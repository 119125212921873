<template>
  <div v-if="settingsReportForm" class="report-edit container">
    <div class="row">
      <el-row>
        <report-bar
          :bannerUrl="bannerUrl"
          :brandLogoUrl="brandLogoUrl"
          :title="settingsReportForm.title"
        ></report-bar>
      </el-row>

      <el-row>
        <el-col :span="15">
          <div
            class="content-draggable"
            :style="
              widgetsAdd.length === 0 ? `background-image:url('${more}');` : ''
            "
          >
            <draggable
              class="dragArea list-group el-row"
              :list="widgetsAdd"
              id="dragArea"
              group="people"
              @change="log"
            >
              <el-col
                :span="columnsSettingWidget(element, 'columns')"
                :key="element.id"
                v-for="element in widgetsAdd"
              >
                <div class="list-group-item widgetadd" :key="element.id">
                  <button @click="deleteItem(element)"><i class="mdi mdi-close"></i></button>
                  <img
                    :src="element.urlImage"
                    alt="Widget"
                    class="widgetAddImageCopy"
                    @click="editForm(element)"
                    @keyup="editForm(element)"
                  />
                  {{ element.name }}
                </div>
              </el-col>
            </draggable>
          </div>
        </el-col>

        <el-col :span="9">
          <div class="tabs-widgets" id="sticky-anchor">
            <el-tabs type="card" @tab-click="handleClick">
              <el-tab-pane label="Widget">
                <draggable
                  :list="widgetTypes"
                  :group="{ name: 'people', pull: 'clone', put: false }"
                  :clone="cloneDog"
                  @change="log"
                  v-if="!settingWidget"
                >
                  <el-col
                    :span="element.cols"
                    :key="element.id"
                    v-for="element in widgetTypes"
                  >
                    <div class="list-group-item widgetList" :key="element.id">
                      <img
                        :src="element.urlImage"
                        alt=""
                        class="widgetAddImage"
                      />
                      {{ element.name }}
                    </div>
                  </el-col>
                </draggable>

                <template v-else>
                  <div class="close-edit-widget">
                    <span
                      @click="settingWidget = false"
                      @keydown="settingWidget = false"
                    ><i class="mdi mdi-close"></i></span>
                  </div>
                  <div class="form-edit-report scroll-gray pt-30"
                       :style=" widgetsAdd.length > 4 ? `height:600px` : `height:490px`">
                    <el-form
                      v-loading="loading"
                      element-loading-text="Saving..."
                      element-loading-background="rgba(0, 0, 0, 0.8)"
                      :model="settingWidgetForm"
                      status-icon
                      :rules="rulesSettings"
                      ref="settingWidgetForm"
                      label-width="120px"
                      class="demo-ruleForm container"
                      :hide-required-asterisk="true"
                      label-position="top"
                    >

                      <div
                        v-for="settingCategory in settingWidgetForm.widget_settings_categories"
                        :key="settingCategory.uuid"
                      >
                        <el-divider content-position="center" class="mb-4 mt-4"
                          >{{
                            Utils.capitalizeString(
                              settingCategory.name
                            ).toUpperCase()
                          }}
                        </el-divider>
                        <el-form-item
                          v-for="brandSetting in settingCategory.widget_settings"
                          :key="brandSetting.uuid"
                          :label="Utils.capitalizeString(brandSetting.name)"
                          :prop="brandSetting.name"
                        >
                          <template v-if="brandSetting.input_type === 'upload'">
                            <custom-upload
                              v-model="brandSetting.value"
                            ></custom-upload>
                          </template>
                          <template
                            v-else-if="brandSetting.input_type === 'text'"
                          >
                            <el-input
                              type="text"
                              v-model="brandSetting.value"
                              autocomplete="off"
                            ></el-input>
                          </template>
                          <template
                            v-else-if="brandSetting.input_type === 'text_area'"
                          >
                            <el-input
                              type="textarea"
                              v-model="brandSetting.value"
                              autocomplete="off"
                            ></el-input>
                          </template>
                          <template
                            v-else-if="
                              brandSetting.input_type === 'number' ||
                              brandSetting.input_type === 'float' ||
                              brandSetting.input_type === 'integer'
                            "
                          >
                            <el-input
                              type="number"
                              min="1"
                              v-model="brandSetting.value"
                              autocomplete="off"
                            ></el-input>
                          </template>
                          <template
                            v-else-if="brandSetting.input_type === 'boolean'"
                          >
                            <el-switch
                              v-model="brandSetting.value"
                              active-value="true"
                              inactive-value="false"
                            ></el-switch>
                          </template>
                        </el-form-item>
                      </div>

                      <el-form-item>
                        <el-button
                          class="btn-secondary"
                          :loading="loading"
                          type="info"
                          @click="settingWidgetSave('settingWidgetForm')"
                          @keypress="settingWidgetSave('settingWidgetForm')"
                          :disabled="loading"
                        >
                          Update
                        </el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </template>
              </el-tab-pane>

              <el-tab-pane label="Settings">
                <template v-if="settingsReportForm">
                  <div class="form-edit-report scroll-gray"
                       :style=" widgetsAdd.length > 4 ? `height:600px` : `height:490px`">
                    <el-form
                      v-loading="loading"
                      element-loading-text="Saving..."
                      element-loading-background="rgba(0, 0, 0, 0.8)"
                      :model="settingsReportForm"
                      status-icon
                      :rules="rulesSettings"
                      ref="settingsReportForm"
                      label-width="120px"
                      class="demo-ruleForm container"
                      :hide-required-asterisk="true"
                      label-position="top"
                    >
                      <el-form-item label="Title" prop="title">
                        <el-input
                          type="text"
                          v-model="settingsReportForm.title"
                          autocomplete="off"
                        >
                        </el-input>
                      </el-form-item>

                      <div
                        v-for="settingCategory in settingsReportForm.setting_categories"
                        :key="settingCategory.uuid"
                      >
                        <el-divider content-position="center" class="mb-4 mt-4"
                          >{{
                            Utils.capitalizeString(
                              settingCategory.name
                            ).toUpperCase()
                          }}
                        </el-divider>
                        <el-form-item
                          v-for="brandSetting in settingCategory.report_settings"
                          :key="brandSetting.uuid"
                          :label="Utils.capitalizeString(brandSetting.name)"
                          :prop="brandSetting.name"
                        >
                          <template v-if="brandSetting.input_type === 'upload'">
                            <custom-upload
                              v-model="brandSetting.value"
                            ></custom-upload>
                          </template>
                          <template
                            v-else-if="brandSetting.input_type === 'text'"
                          >
                            <el-input
                              type="text"
                              v-model="brandSetting.value"
                              autocomplete="off"
                            ></el-input>
                          </template>
                          <template
                            v-else-if="brandSetting.input_type === 'text_area'"
                          >
                            <el-input
                              type="textarea"
                              v-model="brandSetting.value"
                              autocomplete="off"
                            ></el-input>
                          </template>
                          <template
                            v-else-if="
                              brandSetting.input_type === 'number' ||
                              brandSetting.input_type === 'float' ||
                              brandSetting.input_type === 'integer'
                            "
                          >
                            <el-input
                              type="number"
                              min="1"
                              v-model="brandSetting.value"
                              autocomplete="off"
                            ></el-input>
                          </template>
                          <template
                            v-else-if="brandSetting.input_type === 'boolean'"
                          >
                            <el-switch
                              v-model="brandSetting.value"
                              active-value="true"
                              inactive-value="false"
                            ></el-switch>
                          </template>
                        </el-form-item>
                      </div>

                      <el-form-item>
                        <el-button
                          class="btn-secondary"
                          :loading="loading"
                          type="info"
                          @click="settingReport('settingsReportForm')"
                          @keypress="settingReport('settingsReportForm')"
                          :disabled="loading"
                        >
                          Update
                        </el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </template>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>

      <rawisplayer class="col-3" :value="widgetTypes" title="Widgets Types" />
      <rawDisplayer
        class="col-3"
        :value="widgetsAdd"
        title="Widgets Add Types"
      />
    </div>
  </div>
</template>

<script>
import ApiMILO from '@/lib/ApiMILO';
import Utils from '@/utils/Utils';
import draggable from 'vuedraggable';
import widgetBar from '@/assets/widget_types/bar.jpg';
// import widgetLine from '@/assets/widget_types/line.jpg';
import widgetMultiLine from '@/assets/widget_types/multiline.jpg';
import ImgNotFound from '@/assets/img_not_found.png';
// import widgetDoughnut from '@/assets/widget_types/doughnut.jpg';
// import banner from '@/assets/widget_types/banner.png';
// import logo from '@/assets/widget_types/logo.png';
import more from '@/assets/widget_types/more.png';
import ReportBar from '@/components/Bars/ReportBar.vue';
import CustomUpload from '@/components/Uploads/CustomUpload.vue';

export default {
  name: 'ReportEdit',
  props: ['reportId'],
  components: {
    draggable,
    ReportBar,
    CustomUpload,
  },
  data() {
    return {
      Utils,
      settingWidget: false,
      settingWidgetForm: {},
      settingsReportForm: {},
      rulesSettings: {
        title: [
          {
            type: 'string',
            required: true,
            message: 'Please enter report title.',
            trigger: 'blur',
          },
        ],
      },
      loading: false,
      // banner,
      // logo,
      more,
      ImgNotFound,
      tabActive: 'first',
      widgetsAdd: [],
      widgetTypes: [
        {
          id: 1,
          name: 'BAR',
          urlImage: widgetBar,
          cols: 12,
        },
        {
          id: 2,
          name: 'LINEMULTI',
          urlImage: widgetMultiLine,
          cols: 12,
        },
        // {
        //   id: 3,
        //   name: 'LINE',
        //   urlImage: widgetLine,
        //   cols: 12,
        // },
        // {
        //   id: 4,
        //   name: 'DOUGHNUT',
        //   urlImage: widgetDoughnut,
        //   cols: 12,
        // },
      ],
    };
  },
  computed: {
    bannerUrl() {
      let result = '';
      if (this.settingsReportForm?.brand?.brand_settings) {
        const arr = this.settingsReportForm.brand.brand_settings;
        const obj = arr.find((element) => element.name === 'header_hero');
        result = obj && obj.value !== ''
          ? `background-image:url('${obj.value}')`
          : 'background-color: grey';
      }
      return result;
    },
    brandLogoUrl() {
      let result = '';
      if (this.settingsReportForm?.brand?.brand_settings) {
        const arr = this.settingsReportForm.brand.brand_settings;
        const obj = arr.find((element) => element.name === 'header_logo');
        result = obj && obj.value !== '' ? obj.value : ImgNotFound;
      }
      return result;
    },
  },
  watch: {
    widgetsAdd(val) {
      const el = document.getElementById('dragArea');
      if (val.length >= 1) {
        el.style = 'height: 100% !important';
      } else if (val.length === 0) {
        el.style = 'height: 540px !important';
      }
    },
  },
  methods: {
    columnsSettingWidget(widget, setting) {
      let result = 12;
      if (widget.widget_settings_categories) {
        const arrCategory = widget.widget_settings_categories;
        const objCategory = arrCategory.find((element) => element.name === 'general');
        const arr = objCategory?.widget_settings;
        if (Array.isArray(arr)) {
          const obj = arr.find((element) => element.name === setting);
          result = obj && obj.value !== '' ? (24 / parseInt(obj.value, 10)) : 12;
        }
      }
      return result;
    },
    handleClick(tab, event) {
      this.tabActive = tab;
      console.log(tab, event);
    },
    editForm(element) {
      console.log(element);
      this.settingWidget = true;
      this.settingWidgetForm = element;
    },
    async log(evt) {
      try {
        // const { data } = await ApiMILO.post(
        await ApiMILO.post(
          `/report/${this.reportId}/widgets`,
          {
            widgetTypeId: evt.added.element.typeId,
          },
        );
        await this.getWidgets();
        // const indexWidget = this.widgetsAdd.findIndex(
        //   (wADD) => wADD.id === evt.added.element.id,
        // );
        // let objWidget = this.widgetsAdd[indexWidget];
        // objWidget = data;
        // objWidget.name = evt.added.element.name;
        // objWidget.urlImage = evt.added.element.urlImage;
        // this.widgetsAdd[indexWidget] = objWidget;
      } catch (error) {
        console.error(error);
      }
    },
    cloneDog({
      id, name, urlImage, cols,
    }) {
      return {
        id: this.$uuid.v1(),
        typeId: id,
        name,
        urlImage,
        cols,
      };
    },
    deleteItem(element) {
      this.widgetsAdd = this.widgetsAdd.filter(
        (elementF) => elementF.id !== element.id,
      );
      const payload = {
        widgets: this.widgetsAdd,
      };

      try {
        ApiMILO.put(`/report/${this.reportId}/widgets`, payload);
      } catch (e) {
        console.error(e);
      }
    },
    getReport() {
      ApiMILO.getOne(`/report/${this.reportId}`)
        .then((r) => {
          if (r) {
            if (r.success) {
              this.settingsReportForm = r.data;
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    async settingReport(formName) {
      this.loading = true;

      try {
        this.loading = true;
        const valid = await this.$refs[formName].validate();
        if (valid) {
          await this.sendSettings();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async sendSettings() {
      try {
        this.loading = true;
        const r = await ApiMILO.put(
          `/report/${this.reportId}`,
          this.settingsReportForm,
        );
        if (r.success) {
          this.resetForm('settingsReportForm');
          this.getReport();
          this.settingWidget = false;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async settingWidgetSave(formName) {
      this.loading = true;

      try {
        this.loading = true;
        const valid = await this.$refs[formName].validate();
        if (valid) {
          await this.sendSettingsWidget();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async sendSettingsWidget() {
      try {
        this.loading = true;
        const r = await ApiMILO.put(
          `/widget/${this.settingWidgetForm.id}`,
          this.settingWidgetForm,
        );
        if (r.success) {
          this.resetForm('settingWidgetForm');
          this.getReport();
          this.settingWidget = false;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sticky_relocate() {
      let windowTop = '';
      let elem = 0;
      let value = 0;
      windowTop = document.documentElement.scrollTop;
      elem = document.getElementById('sticky-anchor');
      value = 416 - (windowTop > 416 ? 416 : windowTop);
      value = value <= 120 ? 120 : value;
      elem.style.top = `${value}px`;
    },
    scroll() {
      window.addEventListener('scroll', this.sticky_relocate);
    },
    async getWidgets() {
      try {
        const { data } = await ApiMILO.get(`/report/${this.reportId}/widgets`);
        const urlsImages = [
          {
            id: 1,
            url: widgetBar,
          },
          {
            id: 2,
            url: widgetMultiLine,
          },
        ];

        this.widgetsAdd = Object.values(data).map((widget) => {
          const imgWidget = urlsImages.find(
            (widgetImage) => widgetImage.id === widget.widgetTypeId,
          );

          if (imgWidget) {
            return { ...widget, urlImage: imgWidget.url, cols: 12 };
          }
          return { ...widget, cols: 12 };
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    await this.getWidgets();
  },
  mounted() {
    if (this.reportId) {
      this.getReport();
    }
    this.scroll();
  },
};
</script>

<style lang="scss" scoped>
.principal-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  grid-gap: 20px;
}

.content-draggable {
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px dashed #313131;
  border-radius: 6px;
  margin-right: 15px;
  min-height: 540px;
  position: relative;
}
.dragArea.list-group {
  width: 100%;
  height: 540px;
}

.widgetadd,
.widgetList {
  position: relative;
  background: #f8f8f8;
  padding: 15px;
  margin: 5px;
  border-radius: 15px;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background: #fff;
  border: 1px solid #333;
  img {
    object-fit: contain;
    width: 100%;
  }

  button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: transparent;
    border: 0;
    font-size: 20px;
    cursor: pointer;
  }
}

.dragArea {
  .el-col {
    padding: 10px;
  }
}

.widgetAddImage {
  cursor: move;
}
.widgetAddImageCopy {
  cursor: pointer;
}

.tabs-widgets {
  display: inline-grid;
  position: fixed;
  top: 45%;
  overflow: hidden;
  width: 33.5%;
  .el-tabs {
    background: #f8f8f8;
    border-radius: 6px;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    width: 100%;
    border: none;
  }

  .el-tabs--card > .el-tabs__header {
    border: none;
    background: #313131;
    border-radius: 0px 6px;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item {
    border: none;
    color: #ffff;
    font-weight: 700;
    text-align: center;
  }

  .el-tabs__item {
    width: 50%;
    border: none;
  }

  .el-tabs__item.is-active {
    color: #313131 !important;
    background: #f8f8f8 !important;
  }
}
.form-edit-report {
  overflow: auto;
        padding: 30px 20px;

}
.scroll-gray::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f8f8f8;
}

.scroll-gray::-webkit-scrollbar {
  width: 5px;
  background-color: #f8f8f8;
}

.scroll-gray::-webkit-scrollbar-thumb {
  background-color: #31313126;
  border: 1px solid #f8f8f8;
}

.report-edit {
  .el-divider {
    &__text {
      background-color: #f8f8f8 !important;
      text-align: center;
    }
  }

  .close-edit-widget {
    text-align: end;
    margin: 20px;
    position: absolute;
    right: 20px;
    top: 0;
    span{
      cursor: pointer;
      font-size: 20px;
    }
  }
}

</style>
