<template>
  <div class="known-devices">
    <h1>Manage Devices</h1>

    <el-table :loading="loading" v-if="data.length > 0" :data="data" style="width: 100%">
      <el-table-column label="Device name" width="400">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="FingerPrint" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.fingerPrint }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Status" width="100">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.active ? 'success' : 'danger'"
            effect="dark">
            {{ scope.row.active ? 'Active' : 'Inactive' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Registered Date" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.createdAt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Options" width="200">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            ><i class="mdi mdi-pencil"></i></el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            ><i class="mdi mdi-delete"></i></el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div v-else>
      <el-alert
          type="info"
          description="You don't have any added devices."
          :closable="false"
          effect="dark"
          show-icon>
        </el-alert>
    </div>

    <el-dialog
      :closeOnClickModal="false"
      :closeOnPressEscape="false"
      title="Update Device"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
        hide-required-asterisk
        label-position="top"
      >
        <el-form-item label="Device name" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="Status" prop="active">
          <el-switch :active-value="1" inactive-value="0" v-model="ruleForm.active"></el-switch>
        </el-form-item>
        <el-form-item style="display: flex;justify-content: flex-end;">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button
            :loading="loading"
            :disabled="loading"
            type="primary"
            @click="submitForm('ruleForm')"
          >Update</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import ApiSSO from '../../lib/ApiSSO';

export default {
  name: 'KnownDevices',
  components: {},
  props: [
    'userExternal',
    'token',
  ],
  data() {
    return {
      data: {},
      dialogVisible: false,
      loading: false,
      ruleForm: {
        name: '',
        active: null,
        id: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Please input device name',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {},
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setRefreshToken']),
    async getAll() {
      this.loading = true;
      let response = '';
      if (this?.token) {
        axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        response = ApiSSO.getExternal(`/known-devices/${this?.user?.id ?? this.userExternal?.id}`);
      } else {
        response = ApiSSO.get(`/known-devices/${this?.user?.id ?? this.userExternal?.id}`);
      }

      await response
        .then((r) => {
          if (r.success) {
            this.data = Object.values(r.data);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleEdit(index, row) {
      const {
        name,
        active,
        id,
      } = row;

      this.ruleForm = {
        name,
        active,
        id,
      };
      this.dialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm('This will permanently delete the device. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        closeOnClickModal: false,
      }).then(async () => {
        await this.deleteDevice(row.id);
      }).catch(() => {
      });
    },
    async handleUpdateDevice() {
      this.loading = true;

      let response = '';

      if (this?.token) {
        axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        response = ApiSSO.putExternal('/known-devices', this.ruleForm);
      } else {
        response = ApiSSO.put('/known-devices', this.ruleForm);
      }

      await response
        .then(async (r) => {
          if (r.success) {
            this.dialogVisible = false;
            this.$refs.ruleForm.resetFields();
            await this.getAll();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await this.handleUpdateDevice();
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.$refs.ruleForm.resetFields();
      done();
    },
    async deleteDevice(id) {
      this.loading = true;

      let response = '';

      if (this?.token) {
        axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        response = ApiSSO.deleteExternal(`/known-devices/${id}`);
      } else {
        response = ApiSSO.delete(`/known-devices/${id}`);
      }

      await response
        .then(async (r) => {
          if (r.success) {
            await this.getAll();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.getAll();
  },
};
</script>

<style lang="scss">
.known-devices {
  margin-top: 58px;
  @media (min-width: 720px) {
    margin-left: 20px;
  }

  .two-fa-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }

  .setup-authenticator {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fdfeff;
    box-shadow: 0px 4px 12px rgba(98, 96, 96, 0.25);
    border-radius: 4px;
    @media (max-width: 768px) {
      width: 100%;
    }

    .header {
      width: 100%;

      .el-alert.el-alert--info {
        background: #e8f8ff;
        height: 44px;

        p {
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #3fb5e5;
        }

        i {
          color: #3fb5e5;
        }
      }
    }

    ol {
      list-style: decimal !important;
      @media (max-width: 768px) {
        font-size: 14px;
        padding-left: 25px;
      }

      li {
        padding: 10px;

        &::marker {
          font-weight: bold !important;
        }

        .img-code {
          display: flex;
          justify-content: start;
        }

        .code {
          display: flex;
          justify-content: start;
          background: #e9e9e9;
          width: max-content;
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.17em;
          color: #3b3936;
          padding: 0 15px;
          margin: 20px 0;
          z-index: 1;
          position: relative;
        }
      }
    }

    .apps {
      display: flex;
      align-items: center;
    }

    .stores {
      display: flex;
      column-gap: 2px;
      justify-content: center;
      align-items: center;
      padding-left: 5px;

      img {
        height: 24px;
        @media (max-width: 768px) {
          height: 20px;
        }
      }
    }
  }

  .content-modal-user {
    @media (min-width: 720px) {
      padding: 0 20px 0 0;
    }
  }

  .enable-2fa {
    display: flex;
    column-gap: 18px;

    span {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 110%;
      color: #363738;
    }

    p {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #454444;
    }
  }

  .footer-authentication {
    height: 80px;
    background: #ffffff;
    border-top: 1px solid #e5e5e5;
    margin-top: 50px;
    display: flex;
    justify-content: end;
    padding-top: 20px;

    .cancel {
      width: 96px;
      height: 36px;
      background: #f1f1f1;
      border-radius: 4px;
      border: 0;
      color: #363738;
    }

    .submit {
      width: 134px;
      height: 36px;
      background: #b5babc;
      border-radius: 4px;
      border: 0;
      color: #ffffff;
    }
  }
}
</style>
