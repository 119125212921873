<template>
  <div class="simple-table">
    <div v-if="showSearch" class="search-table">
      <span>{{ searchText }}</span>
      <el-input
        v-model="searchString"
        clearable
        size="mini"
        prefix-icon="el-icon-search"
        placeholder="Type something"/>
    </div>
    <el-container class="container-table">
      <el-table
        v-loading="loading"
        :data="data"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :default-sort="{prop: 'id', order: 'descending'}">
        <template v-for="(column, key)  in tableColumns">
          <el-table-column
            v-if="column?.isActions"
            :key="`column-${key}`"
            :prop="column.prop"
            :label="column.label"
            :sortable="column.sortable"
            :align="column.align ?? 'center'">
            <template slot-scope="scope">
              <template v-if="column.typeButtons === 'links'">
                <div v-for="btn in column.buttons" :key="btn.uuid">
                  <el-link
                    @click.native.prevent="validateHandle(scope.$index, scope.row, btn.action)"
                  ><i v-if="btn.icon" :class="`mdi ${btn.icon}`"></i> {{ btn.text }}
                  </el-link>
                </div>
              </template>
              <template v-else-if="column.typeButtons === 'dropdowns'">
                <el-tooltip
                  v-for="item in column.dropdown" :key="item.uuid"
                  :content="`${item.text} ${renderStatus(item.action, scope.row)}`"
                  placement="top">
                  <el-link
                    class="icons-actions"
                    @click.stop="validateHandle(scope.$index, scope.row, item.action)"
                  ><i :class="`mdi ${iconAction(item.action, scope.row)}`"></i>
                  </el-link>
                </el-tooltip>
              </template>
            </template>
          </el-table-column>

          <template v-else-if="column?.type === 'image'">
            <el-table-column
              :key="`column1-${key}`"
              :prop="column.prop"
              :sortable="column.sortable"
              :align="column.align ?? 'center'">
              <template slot-scope="scope">
                <avatar
                  v-if="!scope.row.profilePicture"
                  :fullname="`${scope.row.firstname} ${scope.row.lastname}`"
                  class="avatar-text"
                  :class="`user-${scope?.row?.active ? 'active' : 'inactive'}`"
                  color="#214BEB"
                ></avatar>
                <el-avatar
                  v-else
                  :class="`user-${scope?.row?.active ? 'active' : 'inactive'}`"
                  shape="circle"
                  fit="cover"
                  :size="50"
                  :src="scope.row.profilePicture"
                ></el-avatar>
              </template>
            </el-table-column>
          </template>

          <template v-else-if="column?.type === 'status'">
            <el-table-column
              :key="`column2-${key}`"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable"
              :align="column.align ?? 'center'">
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.active ? 'success' : 'danger'"
                  effect="dark">
                  {{ scope.row.active ? 'Active' : 'Inactive' }}
                </el-tag>
              </template>
            </el-table-column>
          </template>

          <template v-else>
            <el-table-column
              v-if="column.format"
              :key="`column-${key}`"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable"
              :align="column.align ?? 'center'"
            >
              <template slot-scope="scope">
                <span>{{ format(column.format, scope.row.createdAt) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :key="`column-${key}`"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable"
              :align="column.align ?? 'center'">
            </el-table-column>
          </template>
        </template>
      </el-table>
    </el-container>
  </div>
</template>

<script>

import Avatar from 'vue-avatar-component';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'SimpleTable',
  components: {
    Avatar,
  },
  props: ['data', 'tableColumns', 'loading', 'firstColumn', 'searchText', 'showSearch'],
  data() {
    return {
      tableData: [],
      searchString: '',
      debounceSearch: null,
      applicationId: null,
    };
  },
  watch: {
    searchString() {
      this.debounceSearch();
    },
  },
  methods: {
    renderStatus(action, row) {
      if (action === 'changeStatus') {
        return row?.active ? 'to Inactive' : 'to Active';
      }
      return '';
    },
    tableRowClassName({ rowIndex }) {
      /* eslint-disable */
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    handleView(index, row) {
      // console.log(index, row);
      this.$emit('viewRow', row);
    },
    handleResetPassword(index, row) {
      // console.log(index, row);
      this.$emit('viewResetPassword', row);
    },
    handlePermissionsRol(index, row) {
      // console.log(index, row);
      // this.$emit('viewPermissions', row);
      this.$router.push({
        path: `/roles/${row.id}/permissions`,
        query: {
          dataRole: row,
          title: 'Permissions Role',
          applicationId: this.applicationId
        }
      });
    },
    handlePermissionsUser(index, row) {
      // console.log(index, row);
      // this.$emit('viewPermissions', row);
      this.$router.push({
        path: `/users/${row.id}/permissions`,
        query: {
          dataUser: row,
          title: 'Permissions User',
          applicationId: this.applicationId
        }
      });
    },
    validateHandle(index, row, action) {
      if (action === 'view') {
        this.handleView(index, row);
      } else if (action === 'edit') {
        this.handleEdit(index, row);
      } else if (action === 'changeStatus') {
        this.handleChangeStatus(index, row);
      } else if (action === 'hide') {
        this.handleHide(index, row);
      } else if (action === 'delete') {
        this.handleDelete(index, row);
      } else if (action === 'permissions-rol') {
        this.handlePermissionsRol(index, row);
      } else if (action === 'permissions-user') {
        this.handlePermissionsUser(index, row);
      }else if (action === 'resetPassword') {
        this.handleResetPassword(index, row);
      }
    },
    handleEdit(index, row) {
      this.$root.$emit('editRow', { id: row.id, slug: row.slug ?? null, type: this.$route.name });
    },
    handleHide(index, row) {
      // console.log(index, row);
      this.$confirm('This will permanently hide the user. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.$root.$emit('hideRow', row.id);
      }).catch(() => {
      });

    },
    handleChangeStatus(index, row) {
      this.$confirm(`This will ${row?.active ? 'Inactive' : 'Active'} this. Continue?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.$root.$emit('changeStatus', row);
      }).catch(() => {
      });

    },
    handleDelete(index, row) {
      // console.log(index, row);
      this.$confirm('This will permanently delete the user. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.$root.$emit('deleteRow', row.id);
      }).catch(() => {
      });

    },
    format(type, item) {
      if (type === 'fromNow') {
        return moment(item).fromNow();
      }
    },
    emitSearch() {
      this.$emit('searchRow', this.searchString)
    },
    iconAction(action, row) {
      let icon = '';
      if (action === 'edit') {
        icon = 'mdi-pencil';
      }
      if (action === 'hide') {
        icon = 'mdi-eye-off';
      }
      if (action === 'changeStatus') {
        icon = row?.active ? 'mdi-lock' : 'mdi-lock-open';
      }
      if (action === 'delete') {
        icon = 'mdi-delete';
      }
      return icon;
    }
  },
  mounted() {
    this.debounceSearch = _.debounce(this.emitSearch, 500);

    this.applicationId = this.$route.params?.applicationId;
  },
};
</script>
<style lang="scss">
.simple-table {
  .search-table {
    width: 100%;
    // margin: 30px 0 25px;
    // @media (min-width: 720px) {
    //   width: 40%;
    // }

    // span {
    //   color: #868686;
    //   font-weight: 700;
    //   font-size: 20px;
    //   display: inline-block;
    //   margin-bottom: 7px;
    // }
    .el-input--medium {
      font-size: 16px;
    }

    // .el-input--mini .el-input__icon {
    //   color: #ECECEC;
    //   font-size: 15px;
    // }

    // input {
    //   // border: 2px solid #CBD2E0;
    //   // border-radius: 6px;
    // }
  }

  .el-range-editor--medium .el-range-input {
    font-size: 16px;
  }

  .el-range-separator {
    font-size: 16px;
  }

  .container-table {
    padding: 40px 0 !important;

    @media (min-width: 720px) {
      padding: 20px 50px 40px;
    }

    table {
      @media (max-width: 768px) {
        width: 994px !important;
      }

    }

    .el-table .sort-caret.ascending {
      border-bottom-color: #3FB5E5;
    }

    .el-table .sort-caret.descending {
      border-top-color: #3FB5E5;
    }

    .el-table {
      background-color: transparent;
      word-break: break-word;

      .cell {
        @media (max-width: 769px) {
          word-break: break-word;
        }

        a {
          .mdi-pencil, .mdi-eye-off {
            color: #363738;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
          }

          .mdi-delete {
            color: #FF3636;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
          }
          .mdi-lock {
            color: grey;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
          }
          .mdi-lock-open {
            color: green;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
          }
        }

      }

      thead {
        color: #3FB5E5;
        font-weight: 700;
        line-height: 16px;
        background: #F8FDFF;
      }

      &__row {
        color: #313131;
        font-weight: 600;
        // font-size: 18px;
      }

      a {
        color: #214BEB;
        font-weight: 600;

        &:hover {
          color: #214BEB;
        }
      }

      .el-link.is-underline:hover:after {
        border-bottom: transparent;
      }

      th.el-table__cell {
        background-color: transparent;
      }

      th.el-table__cell:last-child {
        text-align: center !important;
      }

      tr {
        background-color: transparent;
      }

      .el-table__cell {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;

        &:last-child {
          text-align: center;
        }

        @media (max-width: 769px) {
          width: 100%;
          word-break: break-word;
        }

        .first-icon-table {
          font-size: 30px;
          color: #214BEB;
        }
      }
    }

    tbody {
      .cell {
        padding-left: 0px;
        padding-right: 0px;
      }

      tr {
        border-bottom: 1pt solid black;
      }

      td:first-child {
        border-left: 3px solid #FFFFFF;
      }

      tr:hover {
        background: #CACBCE30;
        cursor: pointer;

        td:first-child {
          border-left: 3px solid #3FB5E5;
        }
      }
    }

    .el-table__header {
      .cell {
        width: 100% !important;
        padding: 0px;
        @media (min-width: 720px) {
          width: 100% !important;
        }
      }

    }

    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: transparent;
    }

    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
      border-bottom: 0;
    }

    .el-table::before {
      width: 0;
      height: 0;
    }

    .el-table td.el-table__cell div {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #363738;
    }

    .dropdown-list {
      background: #313131;
      border-radius: 6px;
      color: #fff !important;
      font-weight: 600;
      letter-spacing: -0.01em;

      &:hover, &:focus {
        color: #fff;
        border-color: #313131;
        background-color: #313131;
      }
    }
  }

  .icons-actions {
    margin: 5px;

    i {
      font-size: 19px !important;
    }
  }

  .links-actions {
    span {
      color: #3FB5E5 !important;
    }
  }

  .filters {
    margin: 30px 0 0;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 844px) {
      display: grid;
    }
  }

  .user-inactive {
    filter: grayscale(1);
    opacity: 0.6;
  }

  .avatar {
    table {
      tbody {
        tr {
          border-bottom: none !important;
        }

        td:first-child {
          border-left: none !important;
        }

        tr:hover {
          background: transparent !important;
          cursor: auto !important;

          td:first-child {
            border-left: none !important;
          }
        }
      }
    }
  }

  .el-link--inner {
    color: #3FB5E5;
  }

  .avatar {
    color: #ffffff !important;
  }
}

</style>
