<template>
  <div class="permissions-detail">
    <div class="report-navbar">
      <breadcrumb-view :items="breadcrumb"></breadcrumb-view>
    </div>

    <el-container class="container">
      <div class="search-table">
          <el-input
            @change="inputKeyUp"
            v-model="searchText"
            clearable
            size="mini"
            prefix-icon="el-icon-search"
            placeholder="Search press enter"/>
      </div>

      <div class="container">
        <div class="btn-create-permission">
          <el-button
            v-if="roleId"
            type="primary"
            @click="createNewPermission('c483cf1f-6751-49d7-9eb2-21996533ca94',
            'create')">
            Create
            Permission
          </el-button>
        </div>

        <div v-loading="loading" class="permissions">
          <div v-if="filteredData.length > 0">
            <v-jstree
              v-if="renderComponent"
              :data="filteredData"
              show-checkbox
              multiple
              :allow-batch="false"
              whole-row
              @item-click="itemClick"
              ref="tree"
            >
              <template v-if="roleId" slot-scope="$">
                <div
                  style="display: inherit; width: 200px"
                >
                  <i
                    :class="$.vm.themeIconClasses"
                    role="presentation"
                    v-if="!$.model.loading"></i>
                  {{ $.model.text }}
                  <button
                    title="Add new child permission"
                    style="border: 0px; background-color: transparent; cursor: pointer;"
                    @click.prevent.stop="handleModalPermission($.vm, $.model, $event, 'create')"
                  >
                    <i class="mdi mdi-plus-circle"></i>
                  </button>
                  <button
                    title="Edit permission"
                    style="border: 0px; background-color: transparent; cursor: pointer;"
                    @click.prevent.stop="handleModalPermission($.vm, $.model, $event, 'update')"
                  >
                    <i class="mdi mdi-pencil"></i>
                  </button>

                  <button
                    v-if="!$.model?.children?.length > 0"
                    title="Delete permission"
                    style="border: 0px; background-color: transparent; cursor: pointer;"
                    @click.prevent.stop="handleDeletePermission($.vm, $.model, $event)"
                  >
                    <i class="mdi mdi-delete"></i>
                  </button>
                </div>
              </template>
            </v-jstree>
          </div>
          <el-empty v-else :image-size="200"
                    description="No permissions created for this application">
            <el-button
            v-if="roleId"
            type="primary"
                       @click="createNewPermission('c483cf1f-6751-49d7-9eb2-21996533ca94',
                       'create')">
              Create
              Permission
            </el-button>
          </el-empty>
        </div>
      </div>
    </el-container>

    <permissions-modal
      v-if="showModalPermissions"
      :showModal="showModalPermissions"
      :permissionId="permissionId"
      :applicationId="dataRole.applicationId ?? user.applicationId"
      title="Permission"
      :state="statePermissionModal"
    >
    </permissions-modal>

  </div>
</template>

<script>

import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import PermissionsModal from '@/components/Modals/PermissionsModal.vue';
import ApiSSO from '@/lib/ApiSSO';
import VJstree from 'vue-jstree';
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  name: 'PermissionsDetail',
  props: {
    params: {
      type: Object,
      required: true,
    },
    // roleId: {
    //   type: String,
    // },
  },
  components: {
    BreadcrumbView,
    PermissionsModal,
    VJstree,
  },
  data() {
    return {
      roleId: null,
      dataApplication: null,
      showModalPermissions: false,
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      roleDataForm: {},
      dataRole: {},
      dataUser: {},
      permissionId: '',
      statePermissionModal: '',
      searchText: '',
      debounceSearch: null,
      breadcrumb: [
        {
          string: '',
          active: false,
        },
        {
          string: '',
          active: false,
        },
        {
          string: 'Manage Permissions',
          active: true,
        },
      ],
      rules: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter profile name.',
            trigger: 'blur',
          },
        ],
      },
      dataTree: [],
      filteredData: [],
      renderComponent: true,
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
    searchText() {
      this.debounceSearch();
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
    action(form) {
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.state === 'create') {
            return this.sendCreate();
          }
          if (this.state === 'update') {
            return this.sendUpdate();
          }
        }
        this.loading = false;
        return false;
      });
    },
    reset(form) {
      this.$refs[form].resetFields();
    },
    async sendCreate() {
      try {
        this.roleDataForm.applicationId = this.applicationId;
        const r = await ApiSSO.post('/roles', this.roleDataForm);
        if (r.success) {
          this.loading = false;
          this.dialogVisible = false;
          this.reset('roleDataForm');
          this.$root.$emit('reloadDataRoles');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async sendUpdate() {
      try {
        this.loading = true;
        this.roleDataForm.id = this.id;
        const r = await ApiSSO.put('/roles', this.roleDataForm);
        if (r.success) {
          this.dialogVisible = false;
          this.reset('roleDataForm');
          this.$root.$emit('reloadDataRoles');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getDataTree() {
      try {
        this.loading = true;
        const r = await ApiSSO.post('/permissions/rol', {
          rolId: this.dataRole.id,
          applicationId: this.dataRole.applicationId,
        });
        if (r.success) {
          this.dataTree = Object.values(r.data);
          this.filteredData = this.dataTree;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getRole() {
      try {
        const r = await ApiSSO.get(`/roles/${this.roleId}`);
        if (r.success) {
          this.dataRole = { ...r.data };
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    async itemClick(node) {
      this.loading = true;
      if (node.model.selected) {
        if (this.roleId) {
          await this.registerPermission(node.model.id);
        }
        if (this.dataUser.id) {
          await this.registerPermissionUser(node.model.id);
        }
      } else {
        if (this.roleId) {
          await this.removePermission(node.model.id);
        }
        if (this.dataUser.id) {
          await this.removePermissionUser(node.model.id);
        }
      }
      await this.forceRerender();
    },
    async registerPermission(permissionId) {
      try {
        const r = await ApiSSO.post('/permissions/role/add', {
          rolId: this.dataRole.id,
          permissionId,
        });
        if (r.success) {
          this.$message({
            message: 'Permission registered successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async removePermission(permissionId) {
      try {
        const r = await ApiSSO.delete(`/permissions/role/${this.dataRole.id}/delete/${permissionId}`);
        if (r.success) {
          this.$message({
            message: 'Permission revoked successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async deletePermission(permissionId) {
      try {
        const r = await ApiSSO.delete(`/permissions/delete/${permissionId}`);
        if (r.success) {
          await this.reloadDataPermissions();

          this.$message({
            message: 'Permission deleted successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    inputKeyUp() {
      const text = this.searchText.toLowerCase();
      this.filteredData = this.searchByText(this.dataTree, text);
    },
    searchByText(data, searchText) {
      const results = [];

      function recursiveSearch(elements) {
        elements.forEach((element) => {
          if (element.text && element.text.toLowerCase().includes(searchText)) {
            results.push(element);
          }
          if (element.children) {
            recursiveSearch(element.children);
          }
        });
      }

      recursiveSearch(data);
      return results;
    },
    xxx() {
      const text = this.searchText.toLowerCase();
      const patt = new RegExp(text);

      const filterNodes = (node) => {
        if (node.model && patt.test(node.model.text.toLowerCase())) {
          return true;
        }
        if (node.children) {
          return node.children.some(filterNodes);
        }
        return false;
      };

      const highlightNodes = (node) => {
        const el = node.$el.querySelector('.tree-anchor');
        if (filterNodes(node)) {
          el.style.color = 'red';
          if (node.children) {
            node.children.forEach(highlightNodes);
          }
        } else {
          el.style.color = '#000';
        }
      };

      this.$refs.tree.handleRecursionNodeChilds(this.$refs.tree, highlightNodes);
    },
    handleModalPermission(node, item, e, state) {
      e.stopPropagation();
      this.statePermissionModal = state;
      this.permissionId = item?.id ?? null;
      this.showModalPermissions = true;
      // var index = node.parentItem.indexOf(item);
      // node.parentItem.splice(index, 1);
    },
    handleDeletePermission(node, item, e) {
      this.loading = true;
      e.stopPropagation();

      this.$confirm('Are you sure to delete this?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        this.deletePermission(item.id);
      }).catch(() => {
        this.loading = false;
        this.$message({
          type: 'info',
          message: 'Delete canceled',
        });
      });
    },
    createNewPermission(id, state) {
      this.statePermissionModal = state;
      this.permissionId = id;
      this.showModalPermissions = true;
    },
    async getUser(userId) {
      try {
        const r = await ApiSSO.get(`/users/${userId}`);
        if (r.success) {
          this.dataUser = { ...r.data };
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getApplication(applicationId) {
      try {
        const r = await ApiSSO.get(`/applications/${applicationId}`);
        if (r.success) {
          this.dataApplication = { ...r.data };
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getDataTreeUser(userId, applicationId) {
      try {
        this.loading = true;
        const r = await ApiSSO.post('/permissions/user', {
          userId,
          applicationId,
        });
        if (r.success) {
          this.dataTree = Object.values(r.data);
          this.filteredData = this.dataTree;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async registerPermissionUser(permissionId) {
      try {
        const r = await ApiSSO.post('/permissions/user/add', {
          userId: this.dataUser.id,
          permissionId,
        });
        if (r.success) {
          this.$message({
            message: 'Permission registered successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async removePermissionUser(permissionId) {
      try {
        const r = await ApiSSO.delete(`/permissions/user/${this.dataUser.id}/delete/${permissionId}`);
        if (r.success) {
          this.$message({
            message: 'Permission revoked successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.showModalPermissions = false;
    },
    async reloadDataPermissions() {
      this.loading = true;
      this.dataTree = [];
      if (this.roleId) {
        await this.getDataTree();
      }
      if (this.userId) {
        await this.getDataTreeUser();
      }

      this.loading = false;
    },
  },
  async mounted() {
    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('reloadDataPermissions', this.reloadDataPermissions);
    if (this.params?.applicationId) {
      await this.getApplication(this.params?.applicationId);
    }

    if (this.params?.roleId) {
      this.roleId = this.params?.roleId;

      await this.getRole();
      this.breadcrumb = [{
        type: 'image',
        imgUrl: this.dataApplication.logo,
      }, {
        string: this.dataApplication.name,
        active: false,
      }, {
        string: 'Manage Roles',
        active: false,
      }, {
        string: this.dataRole?.name,
        active: false,
      }, {
        string: 'Manage Permissions',
        active: true,
      }];

      await this.getDataTree();
    }
    if (this.params?.userId) {
      await this.getUser(this.params?.userId);

      this.breadcrumb = [{
        type: 'image',
        imgUrl: this.dataApplication.logo,
      }, {
        string: this.dataApplication.name,
        active: false,
      }, {
        string: 'Manage Users',
        active: false,
      }, {
        string: `${this.dataUser?.firstname} ${this.dataUser?.lastname}`,
        active: false,
      }, {
        string: 'Manage Permissions',
        active: true,
      }];

      await this.getDataTreeUser(this.params?.userId, this.params?.applicationId);
    }

    this.debounceSearch = _.debounce(this.inputKeyUp, 500);
  },
};
</script>

<style lang="scss" scoped>
.permissions-detail {
  padding-bottom: 20px;

  .btn-create-permission {
    text-align: end;
    padding-bottom: 15px;
  }

  .search-table {
    margin: 20px;
  }

  .el-form--label-top .el-form-item__label {
    padding: 0 !important;
  }

  .el-form-item__label {
    line-height: 30px !important;
    color: #214BEB !important;
    font-weight: 700;
    letter-spacing: -0.01em;
  }

  .content-modal-user {
    height: 500px;
    overflow-y: scroll;
    padding: 0 20px 0 0;
    @media (max-width: 424px) {
      height: 400px;
    }
  }

  .permissions {
    box-shadow: 0px 0px 12px #e6e6e6;
    border-radius: 6px;
  }
}

.report-navbar {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  background: rgba(63, 181, 229, 0.08);
  height: 145px;
  align-items: center;
  padding: 0 80px;

  @media (min-width: 720px) {
    padding: 0 90px;
    grid-template-rows: auto;
    grid-template-columns: auto auto;
  }

  .principal-bar {
    justify-self: end;
  }

}

</style>
