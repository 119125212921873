<template>
  <div class="principal-bar">
    <div class="title">
      <h1>{{title}}</h1>
    </div>
    <div class="button">
      <el-button
      class="fw-bold btn-secondary"
      @click="action"
      size="medium"><i class="mdi mdi-plus"></i> {{ btnText }}</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PrincipalBar',
  props: ['title', 'btnText', 'actionType', 'routeName'],
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    action() {
      if (this.actionType === 'showModalCreate') {
        this.$root.$emit('openModalCreate');
      }
      if (this.actionType === 'showModalCreateRel') {
        this.$root.$emit('openModalCreateUserRel');
      }
      if (this.actionType === 'goToRoute') {
        this.$router.push({ name: this.routeName });
      }
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss">
  .principal-bar {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // .title {
    //   h1{
    //     font-weight: bold;
    //   }
    // }

    display: flex;

    grid-template-columns: 1fr ;
    grid-row: auto;
    column-gap: 1rem;
    align-items: center;
    justify-content: start;

    @media (min-width: 720px){
    grid-template-columns: 1fr 1fr;
    }

    .title {
      h1{
        font-weight: 400;
        font-size: 24px;
        color: #3FB5E5;
      }
    }

    .button{
      @media (min-width: 720px){
        justify-self: end;
      }
    }
  }
</style>
