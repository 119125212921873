<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="application-modal">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="dialog-footer content-buttons">
        <el-button
          v-if="create"
          class="btn-secondary"
          :loading="loading"
          type="info"
          @click="createApplication('createApplicationForm')"
          @keypress="createApplication('createApplicationForm')"
        >
          Create
        </el-button>
        <div v-if="settings" class="application-modal-buttons">
          <el-button
            class="btn-secondary"
            :loading="loading"
            type="info"
            @click="settingApplication('settingsApplicationForm')"
            @keypress="settingApplication('settingsApplicationForm')"
            :disabled="loading || uploading"
          >
            Update
          </el-button>
        </div>
      </div>
      <div v-loading="loading" :class="settings ? 'content-modal-application scroll-gray' : ''">
        <template v-if="create">
          <el-form
            label-position="top"
            :model="createApplicationForm"
            status-icon
            :rules="rules"
            ref="createApplicationForm"
            label-width="120px"
            class="demo-ruleForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="Name" prop="name">
              <el-input
                type="text"
                v-model="createApplicationForm.name"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="Client" prop="clientId">
              <el-select
                v-model="createApplicationForm.clientId"
                filterable
                placeholder="Select Client"
              >
                <el-option
                  v-for="item in clients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Application Logo" prop="logo">
              <custom-upload v-model="createApplicationForm.logo"></custom-upload>
            </el-form-item>
          </el-form>
        </template>

        <template v-if="settings">
          <el-form
            v-if="settingsApplicationForm.id"
            label-position="top"
            :model="settingsApplicationForm"
            status-icon
            :rules="rulesSettings"
            ref="settingsApplicationForm"
            label-width="120px"
            class="demo-ruleForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="Active" prop="active">
              <el-switch
                :active-value="1"
                :inactive-value="2"
                v-model="settingsApplicationForm.active"></el-switch>
            </el-form-item>

            <el-form-item label="Application Name" prop="name">
              <el-input
                type="text"
                v-model="settingsApplicationForm.name"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="Client" prop="clientId">
              <el-select
                v-model="settingsApplicationForm.clientId"
                filterable
                placeholder="Select Client"
              >
                <el-option
                  v-for="item in clients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Application Logo" prop="logo">
              <custom-upload v-model="settingsApplicationForm.logo"></custom-upload>
            </el-form-item>

            <el-form-item label="Application Id" prop="id">
              <el-input
                readonly
                type="text"
                :value="settingsApplicationForm.id"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="Client Secret key" prop="clientSecret">
              <el-input
                readonly
                :type="showPassword"
                :value="settingsApplicationForm.clientSecret"
                autocomplete="off"
              >
                <el-button
                  @click="handleShowPassword"
                  slot="append"
                  icon="el-icon-view">
                </el-button>
              </el-input>
            </el-form-item>

            <el-form-item label="Redirect URLs" prop="redirectUrls">
              <custom-tags
                v-if="settingsApplicationForm"
                type="url"
                placeholder="Add Redirect URL"
                v-model="settingsApplicationForm.redirectUrls"
              ></custom-tags>
            </el-form-item>

            <el-form-item label="JWT Secret" prop="jwtSecret">
              <el-input
                readonly
                :type="showPassword"
                :value="settingsApplicationForm.jwtSecret"
                autocomplete="off"
              >
                <el-button
                  @click="handleShowPassword"
                  slot="append"
                  icon="el-icon-view">
                </el-button>
                <el-button
                  @click="handleRefreshToken($event, settingsApplicationForm, 'jwtSecret')"
                  slot="append"
                  icon="el-icon-refresh">
                </el-button>
              </el-input>
            </el-form-item>

            <el-form-item label="Issuer" prop="issuer">
              <el-input
                type="text"
                :value="settingsApplicationForm.issuer"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>

            <el-form-item label="Alternative Ips" prop="alternativeIp">
              <custom-tags
                v-if="settingsApplicationForm"
                type="url"
                placeholder="Add Alternative Ip"
                v-model="settingsApplicationForm.alternativeIp"
              ></custom-tags>
            </el-form-item>

            <el-form-item label="Token Expiration Time (minutes)" prop="expirationTime">
              <el-input
                type="text"
                @input="forceNumbers($event, settingsApplicationForm, 'expirationTime')"
                :value="settingsApplicationForm.expirationTime"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>

          </el-form>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import CustomUpload from '@/components/Uploads/CustomUpload.vue';
import CustomTags from '@/components/Tags/CustomTags.vue';
import ApiSSO from '../../lib/ApiSSO';
import Utils from '../../utils/Utils';

export default {
  name: 'ApplicationModal',
  components: {
    CustomUpload,
    CustomTags,
  },
  props: ['showModal', 'title', 'create', 'settings', 'applicationId'],
  data() {
    return {
      Utils,
      showPassword: 'password',
      uploading: false,
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      createApplicationForm: {
        name: '',
        active: true,
      },
      settingsApplicationForm: {
      },
      clients: [],
      rules: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter application name.',
            trigger: 'blur',
          },
        ],
        clientId: [
          {
            type: 'string',
            required: true,
            message: 'Please select a client.',
            trigger: 'blur',
          },
        ],
      },
      rulesSettings: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter application name.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
        this.$root.$emit('removeFiles');
      }
    },
  },
  methods: {
    forceNumbers(e, o, prop) {
      this.$set(o, prop, e.replace(/\D/g, ''));
    },
    handleRefreshToken(e, o, prop) {
      this.$set(o, prop, Utils.generateRandomString(20));
    },
    handleShowPassword() {
      if (this.showPassword === 'password') {
        this.showPassword = 'text';
      } else {
        this.showPassword = 'password';
      }
    },
    handleClose() {
      this.$root.$emit('closeModal');
      this.$root.$emit('removeFiles');
    },
    createApplication(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.sendCreate();
        }
        this.loading = false;
        return false;
      });
    },
    sendCreate() {
      ApiSSO.post('/applications', this.createApplicationForm).then((r) => {
        if (r) {
          if (r.success) {
            this.loading = false;
            this.dialogVisible = false;
            this.resetForm('createApplicationForm');
            this.$root.$emit('reloadApplications');
          }
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async getApplication() {
      this.loading = true;
      await ApiSSO.get(`/applications/${this.applicationId}`).then((r) => {
        if (r) {
          if (r.success) {
            this.settingsApplicationForm = r.data;
            if (this.settingsApplicationForm.redirectUrls == null) {
              this.settingsApplicationForm.redirectUrls = [];
            }
          }
        }
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      });
    },
    async handleDeleteImage(path) {
      const url = '/upload/remove';
      await ApiSSO.post(url, { path });
    },
    async settingApplication(formName) {
      this.loading = true;

      try {
        this.loading = true;
        const valid = await this.$refs[formName].validate();
        if (valid) {
          await this.sendSettings();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async sendSettings() {
      try {
        this.settingsApplicationForm.id = this.applicationId;
        this.loading = true;
        const r = await ApiSSO.put('/applications/', this.settingsApplicationForm);
        if (r.success) {
          this.dialogVisible = false;
          this.resetForm('settingsApplicationForm');
          this.$root.$emit('reloadApplications');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getClients() {
      await ApiSSO.get('/clients').then((r) => {
        if (r.success) {
          this.clients = Object.values(r.data);
        }
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
      });
    },
  },
  async mounted() {
    await this.getClients();
    if (this.applicationId) {
      await this.getApplication();
    }
  },
};
</script>

<style lang="scss">

.application-modal {

  max-width: 585px;

  .content-modal-application {
    height: 500px;
    overflow-y: scroll;
    padding: 0 20px 0 0;
    @media (max-width: 424px) {
      height: 400px;
    }
  }

  .application-modal-buttons {
    @media (max-width: 424px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        margin-bottom: 10px;
      }
    }
    @media (max-width: 768px) {
    }
  }

  .content-buttons {
    margin-bottom: 20px;
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .el-dialog {
    padding: 40px 50px;
    width: 80% !important;
    border-radius: 8px;
    height: auto;
    margin-top: 8vh !important;

    @media (min-width: 720px) {
      width: 40% !important;
    }
    @media (max-width: 768px) {
      padding: 30px 20px;
    }

    .el-dialog__header {
      padding: 10px 0;

      .el-dialog__title {
        font-family: 'Work Sans' !important;
      }
    }

    .el-dialog__body {
      padding: 0;
    }
  }

}
</style>
