<template>
  <div v-if="tokenExternal" class="profile">
    <div v-if="!tokenExternal" class="profile-navbar">
      <breadcrumb-view :items="breadcrumb"></breadcrumb-view>
    </div>
    <div class="el-container container">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="General" name="general">
          <div class="content-upload">
            <edit-profile
              v-if="user?.id || userExternal?.id"
              :userExternal="userExternal"
              :token="tokenExternal"
            ></edit-profile>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Authentication" name="authentication">
          <manage-twofa
            v-if="user?.id || userExternal?.id"
            :userExternal="userExternal"
            :token="tokenExternal"
          ></manage-twofa>
        </el-tab-pane>
        <el-tab-pane label="Known Devices" name="knownDevices">
          <known-devices
            v-if="user?.id || userExternal?.id"
            :userExternal="userExternal"
            :token="tokenExternal"
          ></known-devices>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import ManageTwofa from '@/components/user/ManageTwofa.vue';
import EditProfile from '@/components/user/EditProfile.vue';
import { mapState } from 'vuex';
import KnownDevices from '../../components/user/KnownDevices.vue';

export default {
  name: 'ExternalProfile',
  props: {
    params: {
      type: Object,
    },
  },
  components: {
    BreadcrumbView,
    ManageTwofa,
    EditProfile,
    KnownDevices,
  },
  data() {
    return {
      breadcrumb: [
        {
          string: 'Profile',
          active: true,
        },
      ],
      activeName: 'general',
      userExternal: {},
      tokenExternal: null,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  async mounted() {
    if (this.params?.token) {
      // Decrypt the token
      this.tokenExternal = window.atob(this.params?.token);
      this.userExternal = await this.$jwt.decode(this.tokenExternal).data;
    }
  },
};
</script>

<style lang="scss">
.profile {
  .profile-navbar {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    height: 130px;
    align-content: center;
    padding: 0 80px;
    @media (min-width: 720px) {
      padding: 0 60px;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
    }

    .container-breadcrumb span.el-breadcrumb__item.active span {
      color: #3FB5E5 !important;
    }
  }

  .el-tabs.el-tabs--top {
    width: 100%;
    // @media (min-width: 720px) {
    //   width: 697px;
    // }
  }

  .el-tabs--top .el-tabs__item.is-top {
    padding-left: 30px;
    padding-right: 30px;
  }

  .el-tabs__item.is-active {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    color: #3FB5E5;
    border-bottom: 4px solid #3FB5E5;
  }

  .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #D3D3D3;
  }

  .el-tabs__active-bar {
    background: transparent;
  }

  .el-tabs--top .el-tabs__item.is-top:last-child {
    padding: 0 20px;
  }

  .custom-upload {
    background: #ECECEC;
    padding: 15px;
    border-radius: 4.3445px;
  }
}
</style>
