<template>
  <div class="role-modal">
    <el-dialog
      width="50%"
      center
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose">
        <el-form
          label-position="top"
          :model="roleDataForm"
          status-icon
          ref="roleDataForm"
          label-width="120px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
          :rules="rules">

          <el-form-item label="Role Name" prop="name">
            <el-input
              type="text"
              v-model="roleDataForm.name"
              autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="Description" prop="description">
            <el-input
              type="textarea"
              :rows="2"
              v-model="roleDataForm.description">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              class="btn-secondary-app"
              :loading="loading"
              type="info"
              @click="action('roleDataForm')"
              @keypress="action('roleDataForm')"
              style="width:100%;">{{ state === 'create' ? 'Create': 'Save' }}
            </el-button>
          </el-form-item>

        </el-form>
    </el-dialog>
  </div>
</template>

<script>
import ApiSSO from '@/lib/ApiSSO';

export default {
  name: 'RoleModal',
  props: ['showModal', 'title', 'state', 'id', 'applicationId'],
  components: {
  },
  data() {
    return {
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      roleDataForm: {
        active: true,
      },
      rules: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter profile name.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async getRole() {
      try {
        const r = await ApiSSO.get(`/roles/${this.id}`);
        if (r.success) {
          this.roleDataForm = { ...r.data };
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    action(form) {
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.state === 'create') {
            return this.sendCreate();
          }
          if (this.state === 'update') {
            return this.sendUpdate();
          }
        }
        this.loading = false;
        return false;
      });
    },
    reset(form) {
      this.$refs[form].resetFields();
    },
    async sendCreate() {
      try {
        this.roleDataForm.applicationId = this.applicationId;
        const r = await ApiSSO.post('/roles', this.roleDataForm);
        if (r.success) {
          this.loading = false;
          this.dialogVisible = false;
          this.reset('roleDataForm');
          this.$root.$emit('reloadDataRoles');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async sendUpdate() {
      try {
        this.loading = true;
        this.roleDataForm.id = this.id;
        const r = await ApiSSO.put('/roles', this.roleDataForm);
        if (r.success) {
          this.dialogVisible = false;
          this.reset('roleDataForm');
          this.$root.$emit('reloadDataRoles');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (this.state === 'update') {
      this.getRole();
    }
  },
};
</script>

<style lang="scss">
  .role-modal{
    .el-form--label-top .el-form-item__label {
      padding: 0 !important;
    }

    .el-form-item__content{
      width: 68%;
      margin-right: 0;
    }

    .el-form-item__label {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #3C3A3A;
      letter-spacing: -0.01em;
    }

    .content-modal-user{
        height: 500px;
        overflow-y: scroll;
        padding: 0 20px 0 0;
        @media (max-width: 424px) {
          height: 400px;
        }
      }

    .el-dialog{
  padding: 40px 50px;
  width: 80% !important;
  border-radius: 8px;
  height: auto;
  margin-top: 8vh !important;

  @media (min-width: 720px){
    width: 40% !important;
  }
  .el-dialog__header{
    padding-top: 0;
    .el-dialog__title{
      font-family: 'Work Sans' !important;
      display: flex;
      align-self: left;
    }
  }

  .el-dialog__body{
    padding: 0;
  }
}

.btn-secondary-app{
  width: 134px !important;
  height: 36px !important;
}
  }
</style>
