<template>
  <div class="client-modal">
    <el-dialog
      width="50%"
      center
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="content-modal-client scroll-gray">
        <el-form
          label-position="top"
          :model="clientDataForm"
          status-icon
          ref="clientDataForm"
          label-width="120px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
          :rules="rules">
          <el-form-item label="Client Name" prop="name">
            <el-input
              type="text"
              v-model="clientDataForm.name"
              autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Description" prop="description">
            <el-input
              type="text"
              v-model="clientDataForm.description"
              autocomplete="off"></el-input>
          </el-form-item>
          <div class="client-modal-buttons">
            <el-button
              class="btn-secondary"
              :loading="loading"
              type="info"
              @click="action('clientDataForm')"
              @keypress="action('clientDataForm')"
              >{{ state === 'create' ? 'Create': 'Save' }}
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiSSO from '@/lib/ApiSSO';

export default {
  name: 'ClientModal',
  props: ['showModal', 'title', 'state', 'id', 'brand'],
  data() {
    return {
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      clientDataForm: {
        active: true,
      },
      rules: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter Client name.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async getClient() {
      this.loading = true;
      try {
        const r = await ApiSSO.get(`/clients/${this.id}`);
        if (r.success) {
          this.clientDataForm = { ...r.data };
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    action(form) {
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.state === 'create') {
            return this.sendCreate();
          }
          if (this.state === 'update') {
            return this.sendUpdate();
          }
        }
        this.loading = false;
        return false;
      });
    },
    reset(form) {
      this.$refs[form].resetFields();
    },
    async sendCreate() {
      try {
        const r = await ApiSSO.post('/clients', this.clientDataForm);
        if (r.success) {
          this.loading = false;
          this.dialogVisible = false;
          this.reset('clientDataForm');
          this.$root.$emit('reloadDataClients');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async sendUpdate() {
      try {
        this.loading = true;
        this.clientDataForm.id = this.id;
        const r = await ApiSSO.put('/clients', this.clientDataForm);
        if (r.success) {
          this.dialogVisible = false;
          this.reset('clientDataForm');
          this.$root.$emit('reloadDataClients');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (this.state === 'update') {
      this.getClient();
    }
  },
};
</script>

<style lang="scss" scoped>

.client-modal {

max-width: 585px;

.content-modal-client {
  // height: 500px;
  overflow-y: auto;
  padding: 0 20px 0 0;
  @media (max-width: 424px) {
    height: 400px;
  }
}

.client-modal-buttons {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 424px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 768px) {
  }
}

.content-buttons {
  margin-bottom: 20px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.el-dialog {
  padding: 40px 50px;
  width: 80% !important;
  border-radius: 8px;
  height: auto;
  margin-top: 8vh !important;

  @media (min-width: 720px) {
    width: 40% !important;
  }
  @media (max-width: 768px) {
    padding: 30px 20px;
  }

  .el-dialog__header {
    padding: 10px 0 !important;
    text-align: justify;

    .el-dialog__title {
      font-family: 'Work Sans' !important;
    }
  }

  .el-dialog__body {
    padding: 0;
  }
}

}

</style>
