<template>
  <div>
    <div id="app">
      <el-container
        direction="vertical"
        :class="(isLoggedIn || auth || profileExternal) && !isRecoveryPassword ? '' : 'login'"
      >
        <NavBar v-if="isLoggedIn && (!auth && !profileExternal && !isRecoveryPassword)"></NavBar>

        <el-main>
          <div v-if="showBackButton" class="back-btn">
            <el-tooltip
              class="item"
              effect="dark"
              content="Go to back"
              placement="right">
              <el-button
                @click="goBack">
                <i class="mdi mdi-arrow-left"></i>Back
              </el-button>
            </el-tooltip>
          </div>

          <router-view/>

          <user-modal
            v-if="showModalCreate"
            :showModal="showModalCreate"
            :state="state"
            :title="title">
          </user-modal>

        </el-main>

          <!-- <pre>{{ isLoggedIn }} isLoggedIn</pre>
          <pre>{{ auth }} auth</pre>
          <pre>{{  profileExternal }} profileExternal</pre>
          <pre>{{  isRecoveryPassword }} isRecoveryPassword</pre> -->

        <img
          v-if="evaluateCases(isLoggedIn, auth, profileExternal, isRecoveryPassword)"
          class="img-login"
          :src="logoBg"
          alt="Login Image"
        >
      </el-container>
    </div>
    <Footer v-if="isLoggedIn && (!auth && !profileExternal && !isRecoveryPassword)"></Footer>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import UserModal from '@/components/Modals/UserModal.vue';
import logoBg from '@/assets/login-bg.png';
import ApiSSO from '@/lib/ApiSSO';
import jwtDecode from 'jwt-decode';
import NavBar from './components/NavBar/NavBar.vue';
// import Footer from './components/Footer/Footer.vue';

export default {
  name: 'App',
  components: {
    // Footer,
    NavBar,
    UserModal,
  },
  props: ['reportId'],
  data() {
    return {
      logoBg,
      showBackButton: false,
      showModalCreate: false,
      showModalCreateRel: false,
      state: 'create',
      title: 'Create New User',
      intervalVerifyToken: null,
      flag: false,
      refreshTokenOld: null,
      auth: false,
      profileExternal: false,
      isRecoveryPassword: false,
    };
  },
  watch: {
    $route(val) {
      this.showBackButton = (val.path !== '/' && val.path !== '/login' && val.path !== '/auth'
        && val.path !== '/recovery-password'
        && val.path !== '/reset-password'
        && val.path !== '/externalProfile'
      );
      document.title = `SSO | ${val.name}`;
      if (val.path === '/auth') {
        this.auth = true;
        this.profileExternal = false;
        this.setAuthExternal(true);
      } else if (val.path === '/externalProfile') {
        this.profileExternal = true;
      } else if (val.path === '/recovery-password') {
        this.isRecoveryPassword = true;
      } else {
        this.auth = false;
        this.profileExternal = false;
        this.isRecoveryPassword = false;
        this.setAuthExternal(false);
      }
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isRefreshToken']),
    ...mapState(['token', 'user', 'refreshToken', 'authExternal']),
  },
  methods: {
    ...mapMutations(['setUUID', 'setUser', 'setToken', 'setRefreshToken', 'setAuthExternal']),
    evaluateCases(isLoggedIn, auth, profileExternal, isRecoveryPassword) {
      if (!isLoggedIn && auth && !profileExternal && !isRecoveryPassword) {
        return false; // case 1
      }
      if (!isLoggedIn && !auth && !profileExternal && isRecoveryPassword) {
        return true; // case 2
      }
      if (!isLoggedIn && !auth && profileExternal && !isRecoveryPassword) {
        return false; // case 3
      }
      if (!isLoggedIn && !auth && !profileExternal && !isRecoveryPassword) {
        return true; // case 4
      }
      if (isLoggedIn && !auth && !profileExternal && isRecoveryPassword) {
        return true; // case 5
      }
      return false; // Default case if none of the above conditions match
    },
    goBack() {
      this.$router.go(-1);
    },
    closeModal() {
      this.showModalCreate = false;
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.state = 'create';
      this.title = 'Create User';
    },
    closeModalRel() {
      this.showModalCreateRel = false;
    },
    openModalCreateRel() {
      this.showModalCreateRel = true;
      this.state = 'create';
      this.title = 'Relation User';
    },
    setIntervalVerifyToken() {
      this.intervalVerifyToken = setInterval(async () => {
        if (this.user) {
          await this.validateToken();
        }
      }, 60000);
    },
    async validateToken() {
      const now = Date.now() / 1000;
      const exp = +this.user.exp;
      if (now + 30 >= exp && !this.flag) {
        this.flag = true;
        // refresh token
        console.log('token expired, refresh token', new Date());
        try {
          this.refreshTokenOld = this.refreshToken;
          this.setRefreshToken(null);
          const r = await ApiSSO.post('/refreshToken', {
            applicationId: this.user.applicationId,
            refreshToken: this.refreshTokenOld,
          });
          if (r.success) {
            const { accessToken, refreshToken } = r.data;
            const jwtData = jwtDecode(accessToken);
            const user = jwtData.data;
            user.exp = jwtData.exp;
            this.setUser(user);
            this.setToken(accessToken);
            this.setRefreshToken(refreshToken);
            const dataToken = {
              user,
              accessToken,
              refreshToken,
            };
            const broadcastChannel = new BroadcastChannel('refresh_token');
            broadcastChannel.postMessage({ type: 'update', data: dataToken });
            return;
          }
          this.setUser(null);
          this.setToken(null);
          this.setRefreshToken(null);
          this.setUUID(null);
          this.$router.push({
            path: '/login',
            query: { redirect: this.$route.fullPath },
          });
        } catch (e) {
          console.error(e);
          this.setUser(null);
          this.setToken(null);
          this.setRefreshToken(null);
          this.setUUID(null);
          this.$router.push({
            path: '/login',
            query: { redirect: this.$route.fullPath },
          });
        } finally {
          this.flag = false;
        }
      } else {
        // console.log('no refresh token', new Date());
      }
    },
  },
  created() {
    if (this.user) {
      this.validateToken();
    }
    const broadcastChannel = new BroadcastChannel('refresh_token');
    broadcastChannel.addEventListener('message', (event) => {
      if (event.data.type === 'update') {
        const updatedData = event.data.data;
        console.log('refresh token event', new Date());
        this.setUser(updatedData.user);
        this.setToken(updatedData.accessToken);
        this.setRefreshToken(updatedData.refreshToken);
      }
    });
  },
  mounted() {
    this.setIntervalVerifyToken();

    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('closeModalRel', this.closeModalRel);
    this.$root.$on('openModalCreateUser', this.openModalCreate);
    this.$root.$on('openModalCreateUserRel', this.openModalCreateRel);
  },
  beforeDestroy() {
    clearInterval(this.intervalVerifyToken);
  },
};
</script>

<style lang="scss">
@import '@/styles/style.scss';

.back-btn {
  margin-right: 10px;
  position: relative;
  z-index: 999;

  i {
    font-weight: bold;
    font-size: 16px;
  }

  .el-button {
    border: none;
    position: absolute;
    left: 10px;
    top: 29px;
    background-color: transparent;
    text-align: left;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
    color: #3FB5E5;
    @media (min-width: 720px) {
      left: 30px;
    }
  }

  .el-button:hover {
    background-color: transparent;
    border: none;
  }

  .el-button:active {
    background-color: transparent;
    border: none;
  }

  .el-button:focus {
    background-color: transparent;
    border: none;
  }
}

.el-main {
  padding: 0;
}
</style>
