<template>
  <div class="users">
    <div class="report-navbar">
      <breadcrumb-view :items="breadcrumb"></breadcrumb-view>

      <div class="action-buttons">
        <principal-bar
          btnText="Create New"
          actionType="showModalCreate"
        ></principal-bar>

        <principal-bar
          v-if="applicationId"
          btnText="Link User"
          actionType="showModalCreateRel"
        ></principal-bar>
      </div>
    </div>
    <simple-table
      @viewRow="viewDataUser"
      @viewResetPassword="viewResetPassword"
      @viewPermissions="viewPermissionsModal"
      @searchRow="handleSearch"
      :showSearch="true"
      :data="users"
      :tableColumns="columns"
      :loading="loading"
      :firstColumn="firstColumn"
      class="container"
    ></simple-table>

    <simple-paginator
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :currentPage="paginator.currentPage"
      :pageSize="paginator.pageSize"
      :total="paginator.total"
      :pagerCount="5"
      layout="total, sizes, prev, pager, next, jumper"
      class="container"
    ></simple-paginator>

    <user-modal
      v-if="showModalCreate"
      :showModal="showModalCreate"
      :state="state"
      :id="userId"
      :title="title">
    </user-modal>

    <rel-user-modal
      v-if="showModalCreateRel"
      :showModal="showModalCreateRel"
      :applicationId="applicationId"
      :title="title">
    </rel-user-modal>

    <view-modal
      v-if="showModalView"
      :showModal="showModalView"
      :data="dataUserItem">
    </view-modal>

    <reset-password-modal
      v-if="showResetPassword"
      :showModal="showResetPassword"
      :title="`Reset Password for ${dataUserItem.email}`"
      :data="dataUserItem"
    >
    </reset-password-modal>

    <permissions-user-modal
      v-if="showModalViewPermissions"
      :showModal="showModalViewPermissions"
      :dataUser="dataUserItem"
      title="Permissions"
    >
    </permissions-user-modal>
  </div>
</template>

<script>

import ApiSSO from '@/lib/ApiSSO';
import SimpleTable from '@/components/Tables/SimpleTable.vue';
import PrincipalBar from '@/components/Bars/PrincipalBar.vue';
import UserModal from '@/components/Modals/UserModal.vue';
import RelUserModal from '@/components/Modals/RelUserModal.vue';
import ViewModal from '@/components/Modals/ViewModal.vue';
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import ResetPasswordModal from '@/components/Modals/ResetPasswordModal.vue';
import PermissionsUserModal from '@/components/Modals/PermissionsUserModal.vue';
import SimplePaginator from '@/components/Paginator/SimplePaginator.vue';

export default {
  name: 'UsersView',
  components: {
    BreadcrumbView,
    ViewModal,
    UserModal,
    RelUserModal,
    SimpleTable,
    PrincipalBar,
    ResetPasswordModal,
    PermissionsUserModal,
    SimplePaginator,
  },
  props: ['applicationId'],
  data() {
    return {
      search: null,
      loading: true,
      showModalCreate: false,
      showModalCreateRel: false,
      showModalEdit: false,
      showModalView: false,
      showResetPassword: false,
      showModalViewPermissions: false,
      users: [],
      application: {},
      dataUserItem: {},
      state: 'create',
      title: 'Create New User',
      userId: null,
      breadcrumb: [
        {
          string: 'Manage All Users',
          active: true,
        },
      ],
      columns: [
        {
          prop: 'profilePicture',
          label: 'Avatar',
          sortable: false,
          type: 'image',
        },
        {
          prop: 'firstname',
          label: 'First Name',
          sortable: true,
        },
        {
          prop: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          prop: 'organization',
          label: 'Organization',
          sortable: true,
        },
        {
          prop: 'active',
          label: 'Status',
          sortable: true,
          type: 'status',
        },
        {
          prop: 'createdAt',
          label: 'Created Date',
          sortable: true,
          format: 'fromNow',
        },
        {
          prop: 'actions',
          label: 'Actions',
          sortable: false,
          isActions: true,
          typeButtons: 'links',
          align: 'left',
          buttons: [
            {
              icon: 'mdi-eye',
              text: 'View',
              action: 'view',
            },
            {
              icon: 'mdi-asterisk',
              text: 'Reset Password',
              action: 'resetPassword',
            },
            // {
            //   text: 'Permissions',
            //   action: 'permissions-user',
            //   icon: 'mdi-shield-key',
            // },
          ],
        },
        {
          prop: 'admin',
          label: 'Admin',
          sortable: false,
          isActions: true,
          typeButtons: 'dropdowns',
          dropdown: [
            {
              text: 'Edit',
              action: 'edit',
            },
            {
              text: 'Change Status',
              action: 'changeStatus',
            },
            {
              text: 'Delete',
              action: 'delete',
            },
          ],
        },
      ],
      firstColumn: {
        show: false,
        icon: 'mdi-chart-box-outline',
      },
      paginator: {
        currentPage: 0,
        pageSize: 10,
        total: 0,
        page: 1,
      },
    };
  },
  watch: {
    // $route() {
    //   this.application = {};

    // },
  },
  methods: {
    async getAll(search = null) {
      this.loading = true;
      const paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}`;
      const param = this.applicationId ? `/application/${this.applicationId}` : '';

      let url = `/users${param}?${paginate}`;

      if (search) {
        url = `/users${param}?${paginate}&search=${search}`;
      }

      await ApiSSO.get(url).then((r) => {
        if (r.success) {
          this.users = Object.values(r.data);

          if (r.paginator) {
            this.paginator.currentPage = r.paginator.page;
            this.paginator.pageSize = r.paginator.page_size;
            this.paginator.total = r.paginator.count;
          }
        }
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    closeModal() {
      this.showModalView = false;
      this.showModalEdit = false;
      this.showModalCreate = false;
      this.showModalCreateRel = false;
      this.showResetPassword = false;
      this.showModalViewPermissions = false;
    },
    viewPermissionsModal(row) {
      this.dataUserItem = row;
      this.showModalViewPermissions = true;
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.state = 'create';
      this.title = 'Create User';
    },
    openModalCreateRel() {
      this.showModalCreateRel = true;
      this.title = 'Link User';
    },
    viewDataUser(row) {
      this.dataUserItem = row;
      this.showModalView = true;
    },
    viewResetPassword(row) {
      this.dataUserItem = row;
      this.showResetPassword = true;
    },
    editDataUser(data) {
      if (data.type === 'Users' || data.type === 'Users from Application') {
        this.showModalCreate = true;
        this.state = 'update';
        this.title = 'Update User';
        this.userId = data.id;
      }
    },
    async hideUser(id) {
      try {
        const r = await ApiSSO.put('/users', {
          id,
          active: false,
        });
        if (r.success) {
          this.getAll(this.search);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async changeStatus(row) {
      this.loading = true;
      try {
        const r = await ApiSSO.put('/users', {
          id: row.id,
          active: !row?.active,
        });
        if (r.success) {
          this.getAll(this.search);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteUser(id) {
      try {
        const r = await ApiSSO.delete(`/users/${id}`);
        if (r.success) {
          this.getAll();
          this.$message({
            message: r?.data?.message ?? r?.message,
            type: 'success',
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getApplication(id) {
      await ApiSSO.get(`/applications/${id}`).then((r) => {
        if (r.success) {
          this.application = r.data;
        }
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
      });
    },
    currentChange(val) {
      this.paginator.page = val;
      this.getAll();
    },
    sizeChange(val) {
      this.paginator.pageSize = val;
      this.paginator.handlesSizeChange = true;
      this.getAll();
    },
    handleSearch(string) {
      this.search = string;
      this.getAll(string);
    },
    getAllSearch() {
      this.getAll(this.search);
    },
  },
  updated() {

  },
  async mounted() {
    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('openModalCreate', this.openModalCreate);
    this.$root.$on('reloadDataUsers', this.getAllSearch);

    this.$root.$on('openModalCreateUserRel', this.openModalCreateRel);

    // TABLE EMITS
    this.$root.$on('editRow', this.editDataUser);
    this.$root.$on('hideRow', this.hideUser);
    this.$root.$on('changeStatus', this.changeStatus);
    this.$root.$on('deleteRow', this.deleteUser);

    await this.getAll();

    const addOption = this.columns.find((field) => field.prop === 'actions');

    addOption.buttons.push(
      {
        text: 'Permissions',
        action: 'permissions-user',
        icon: 'mdi-shield-key',
      },
    );

    if (this.applicationId) {
      await this.getApplication(this.applicationId);
      this.breadcrumb = [{
        type: 'image',
        imgUrl: this.application.logo,
      }, {
        string: this.application.name,
        active: false,
      }, {
        string: 'Manage Users',
        active: true,
      }];
    } else {
      this.breadcrumb = [
        {
          string: 'Home',
          active: false,
          link: '/',
        },
        {
          string: 'Manage All Users',
          active: true,
        },
      ];
      this.getAll();
    }
  },
};
</script>

<style lang="scss" scoped>

.users {
  overflow: hidden;

  .report-navbar {
    display: flex;
    justify-content: space-between;
    grid-template-rows: auto;
    grid-template-columns: auto;
    background: #F0F9FD;
    height: 100px;
    align-items: center;
    padding: 0 80px;

    @media (min-width: 720px) {
      padding: 0 90px;
      grid-template-rows: auto;
      grid-template-columns: auto 150px 150px;
    }

    .action-buttons {
      display: flex;
    }

  }
}
</style>
