<template>
  <div class="container applications">
    <h1>Applications ({{ applications?.length ?? 0 }})</h1>
    <div class="search-table">
      <el-input
        v-model="searchString"
        clearable
        size="mini"
        prefix-icon="el-icon-search"
        placeholder="Search by application name"
      />

      <el-button
        class="btn-secondary"
        @click="openModalCreate()"
        size="medium"
      >
        <i class="mdi mdi-plus"></i>
        Create New
      </el-button>
    </div>

    <el-container v-loading="loading" v-if="applications.length > 0">
      <div class="cards">
<!--        <principal-bar
          btnText="Create new Application"
          actionType="showModalCreate"
        ></principal-bar>
-->
        <el-card
          v-for="application in applications"
          :key="application.uuid"
          shadow="hover"
          :body-style="{ padding: '0px', border: 'none' }"
        >
          <div class="image-container" style="position: relative">
            <img
              width="100%"
              :src="getApplicationLogo(application.logo)"
              class="image img-application-size"
              alt="img"
            />
            <div class="cursor-pointer settings-icon">
              <i
                @keyup="openModalSettings(application.id)"
                @click="openModalSettings(application.id)"
                class="mdi mdi-cog-outline"
              ></i>
            </div>
          </div>

          <div class="foot">
            <div class="button clearfix">
              <div>
                <p class="fw-bold">{{ application.name }}</p>
              </div>
              <div>
                <el-tooltip content="Roles" placement="bottom">
                  <el-button
                    class="btn-secondary"
                    circle
                    @keyup="goTo('roles', application)"
                    @click="goTo('roles', application)"
                    size="medium"
                  >
                    <i class="mdi mdi-cog"></i>
                  </el-button>
                </el-tooltip>

                <el-tooltip content="Users" placement="bottom">
                  <el-button
                    class="btn-secondary"
                    circle
                    @keyup="goTo('users', application)"
                    @click="goTo('users', application)"
                    size="medium"
                  >
                    <i class="mdi mdi-account-group"></i>
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
        </el-card>
      </div>

      <simple-paginator
        @currentChange="currentChange"
        @sizeChange="sizeChange"
        :currentPage="paginator.currentPage"
        :pageSize="paginator.pageSize"
        :total="paginator.total"
        :pagerCount="5"
        layout="total, sizes, prev, pager, next, jumper"
        class="container"
      ></simple-paginator>
    </el-container>

    <el-empty v-else :image-size="200"></el-empty>

    <application-modal
      v-if="showModalCreate"
      :showModal="showModalCreate"
      :create="true"
      title="Create Application"
    >
    </application-modal>

    <application-modal
      v-if="showModalSettings"
      :showModal="showModalSettings"
      :settings="true"
      title="Application Settings"
      :applicationId="applicationId"
    >
    </application-modal>
  </div>
</template>

<script>
import ApiSSO from '@/lib/ApiSSO';
import ApplicationModal from '@/components/Modals/ApplicationModal.vue';
import ImgNotFound from '@/assets/img_not_found.png';
import SimplePaginator from '@/components/Paginator/SimplePaginator.vue';
// import PrincipalBar from '@/components/Bars/PrincipalBar.vue';
import _ from 'lodash';
// import Avatar from 'vue-avatar-component';

export default {
  name: 'ApplicationsView',
  components: {
    // Avatar
    // PrincipalBar,
    ApplicationModal,
    SimplePaginator,
  },
  data() {
    return {
      searchString: '',
      debounceSearch: null,
      loading: true,
      showModalCreate: false,
      showModalSettings: false,
      applications: [],
      currentDate: new Date(),
      applicationId: '',
      paginator: {
        currentPage: 0,
        pageSize: 10,
        total: 0,
        page: 1,
      },
    };
  },
  watch: {
    searchString() {
      this.debounceSearch();
    },
  },
  methods: {
    getApplications(search = null) {
      this.loading = true;
      const paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}`;

      let url = `/applications?${paginate}`;

      if (search) {
        url = `/applications?${paginate}&search=${search}`;
      }

      ApiSSO.get(url)
        .then((r) => {
          this.loading = false;

          this.applications = Object.values(r.data);

          if (r.paginator) {
            this.paginator.currentPage = r.paginator.page;
            this.paginator.pageSize = r.paginator.page_size;
            this.paginator.total = r.paginator.count;
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log('🚀 ----------------------------🚀');
          console.log('🚀 ~ getApplications ~ e:', e);
          console.log('🚀 ----------------------------🚀');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goTo(routeName, obj) {
      this.$router.push({ path: `/applications/${obj.id}/${routeName}` });
    },
    closeModal() {
      this.showModalCreate = false;
      this.showModalSettings = false;
    },
    openModalSettings(applicationId) {
      this.showModalSettings = true;
      this.applicationId = applicationId;
    },
    getApplicationLogo(logo) {
      if (logo) {
        return logo;
      }
      return ImgNotFound;
    },
    sizeChange(val) {
      this.paginator.pageSize = val;
      this.paginator.handlesSizeChange = true;
      this.getApplications();
    },
    currentChange(val) {
      this.paginator.page = val;
      this.getApplications();
    },
    openModalCreate() {
      this.showModalCreate = true;
    },
    emitSearch() {
      this.getApplications(this.searchString);
    },
  },
  mounted() {
    this.getApplications();
    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('openModalCreate', this.openModalCreate);
    this.$root.$on('reloadApplications', this.emitSearch);
    this.$root.$on('currentChange', this.currentChange);

    this.debounceSearch = _.debounce(this.emitSearch, 500);
  },
};
</script>

<style lang="scss">

.container {
  width: 86% !important;
  padding-top: 25px !important;
}

.applications {
  .el-container {
    display: block;
    flex-direction: column !important;
  }
  max-width: 1390px;
  h1 {
    font-weight: 400;
    font-size: 24px;
    color: #3fb5e5;
  }

  .search-table {
    width: 100%;
    margin: 30px 0 25px;
    // @media (min-width: 720px){
    // width: 40%;
    // }

    .el-input__inner {
      height: 36px;
      font-size: 16px;
    }

    span {
      color: #868686;
      font-weight: 700;
      font-size: 20px;
      display: inline-block;
      margin-bottom: 7px;
    }

    .el-input--mini .el-input__icon {
      color: #ececec;
      font-size: 15px;
    }

    input {
      border: 2px solid #cbd2e0;
      border-radius: 6px;
    }
  }

  .cards {
    display: flex;
    gap: 25px;
    justify-content: start;
    flex-wrap: wrap;
    // max-width: 1000px;
    margin: auto;

    @media (max-width: 768px) {
      justify-content: center;
    }

    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .el-card {
      background: #f7f8ff;
      width: 251.12px;
      height: 274px;
      border: none;
      margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 12px #e6e6e6;
      border-radius: 6px;
      display: grid;

      .foot {
        margin: 5px 0 15px;

        .fw-bold {
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #000000;
        }
      }

      .settings-icon {
        transition: all $durationAnimation;
        position: absolute;
        top: 5px;
        right: 5px;
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);

        &:hover {
          color: #a4a7ac;
        }

        i {
          font-size: 25px;
        }
      }
    }
  }

  .search-table {
    display: flex;
    align-items: center;
    gap: 10px;

    .btn-secondary {
      background-color: #ffffff;
      border: solid 1px#3fb5e5;
      box-shadow: 0px 0px 12px #e6e6e6;
      border-radius: 6px;
      span {
        color: #3FB5E5;
        font-size: 14px;
        line-height: 14px;
        font-weight: 700;
        font-size: normal;
        margin: 0;
      }
      &:hover {
        background-color: #3fb5e5;
        span {
          color: #ffffff;
        }
      }
    }
  }

  .title {
    color: #214beb;
    font-size: 24px;
    font-weight: bold;
  }

  .img-application-size {
    min-width: 170px;
    max-height: 129px;
    height: 129px;
    object-fit: contain;
    max-width: 200px;
  }

  .image-container {
    display: grid;
    justify-content: center;
  }
}
</style>
