<template>
  <div class="permissions-detail">
    <div class="report-navbar">
      <breadcrumb-view :items="breadcrumb"></breadcrumb-view>
    </div>

    <div class="principal-container">
      <div class="principal-content" :style="stylesContainer">
        <div v-if="this.params?.userId" class="col-1">
          <section class="applications-section">
            <h1 class="title-section">Assign Applications</h1>

            <el-transfer
              class="custom-transfer"
              v-loading="loading"
              filterable
              :filter-method="filterMethod"
              filter-placeholder="Search by name"
              v-model="userApplications"
              :props="{
                key: 'id',
                label: 'name',
              }"
              :titles="['All Applications', 'User Applications']"
              :button-texts="['', '']"
              :format="{
                noChecked: '${total}',
                hasChecked: '${checked}/${total}',
              }"
              :data="dataApplications"
              @change="handleChangeApplications"
            >
            </el-transfer>
          </section>

          <section class="userApplications">
            <h1 class="title-section">Assign Roles</h1>

            <el-tabs
              v-if="userApplications.length > 0"
              v-model="activeTabApplication"
              v-loading="loading"
              tab-position="left"
              type="border-card"
              @tab-click="handleTabClick"
              style="height: 430px;"
            >
              <el-tab-pane
                v-for="item in userApplications"
                :key="item"
                :label="renderAppNAme(item)"
                :id="item"
                :name="item"
              >
                <h1>{{ renderAppNAme(item) }}</h1>
                <el-transfer
                  filterable
                  :filter-method="filterMethod"
                  filter-placeholder="Search by name"
                  v-model="userRoles"
                  :props="{
                    key: 'id',
                    label: 'name',
                  }"
                  :titles="['Application Roles', 'User Roles']"
                  :button-texts="['', '']"
                  :format="{
                    noChecked: '${total}',
                    hasChecked: '${checked}/${total}',
                  }"
                  :data="dataRolesApplication"
                  @change="handleChangeRoles"
                >
                </el-transfer>
              </el-tab-pane>
            </el-tabs>
          </section>
        </div>

        <div class="col-2">
          <section class="permissions-sections">
            <h1 class="title-section">Assign Permissions</h1>

            <div class="search-table">
              <el-input
                @change="inputKeyUp"
                v-model="searchText"
                clearable
                size="mini"
                prefix-icon="el-icon-search"
                placeholder="Search by name"
              />
            </div>

            <div class="container-permissions scroll-style-5">
              <div class="btn-create-permission">
                <el-button
                  v-if="roleId"
                  type="primary"
                  @click="
                    createNewPermission(
                      'c483cf1f-6751-49d7-9eb2-21996533ca94',
                      'create'
                    )
                  "
                >
                  Create Permission
                </el-button>
              </div>

              <el-container v-loading="loading" class="permissions">
                <div v-if="filteredData.length > 0">
                  <v-jstree
                    v-if="renderComponent"
                    :data="filteredData"
                    show-checkbox
                    multiple
                    :allow-batch="false"
                    whole-row
                    @item-click="itemClick"
                    ref="tree"
                  >
                    <template v-if="roleId" slot-scope="$">
                      <div style="display: inherit; width: 200px">
                        <i
                          :class="$.vm.themeIconClasses"
                          role="presentation"
                          v-if="!$.model.loading"
                        ></i>
                        {{ $.model.text }}
                        <button
                          title="Add new child permission"
                          style="
                            border: 0px;
                            background-color: transparent;
                            cursor: pointer;
                          "
                          @click.prevent.stop="
                            handleModalPermission($.vm, $.model, $event, 'create')
                          "
                        >
                          <i class="mdi mdi-plus-circle"></i>
                        </button>
                        <button
                          title="Edit permission"
                          style="
                            border: 0px;
                            background-color: transparent;
                            cursor: pointer;
                          "
                          @click.prevent.stop="
                            handleModalPermission($.vm, $.model, $event, 'update')
                          "
                        >
                          <i class="mdi mdi-pencil"></i>
                        </button>

                        <button
                          v-if="!$.model?.children?.length > 0"
                          title="Delete permission"
                          style="
                            border: 0px;
                            background-color: transparent;
                            cursor: pointer;
                          "
                          @click.prevent.stop="
                            handleDeletePermission($.vm, $.model, $event)
                          "
                        >
                          <i class="mdi mdi-delete"></i>
                        </button>
                      </div>
                    </template>
                  </v-jstree>
                </div>
                <el-empty
                  v-else
                  :image-size="200"
                  description="No permissions created for this application"
                >
                  <el-button
                    v-if="roleId"
                    type="primary"
                    @click="
                      createNewPermission(
                        'c483cf1f-6751-49d7-9eb2-21996533ca94',
                        'create'
                      )
                    "
                  >
                    Create Permission
                  </el-button>
                </el-empty>
              </el-container>
            </div>
          </section>
        </div>
      </div>
    </div>

    <permissions-modal
      v-if="showModalPermissions"
      :showModal="showModalPermissions"
      :permissionId="permissionId"
      :applicationId="dataRole.applicationId ?? user.applicationId"
      title="Permission"
      :state="statePermissionModal"
    >
    </permissions-modal>
  </div>
</template>

<script>
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import PermissionsModal from '@/components/Modals/PermissionsModal.vue';
import ApiSSO from '@/lib/ApiSSO';
import VJstree from 'vue-jstree';
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  name: 'PermissionsDetail',
  props: {
    params: {
      type: Object,
      required: true,
    },
    // roleId: {
    //   type: String,
    // },
  },
  components: {
    BreadcrumbView,
    PermissionsModal,
    VJstree,
  },
  data() {
    return {
      roleId: null,
      dataApplication: null,
      showModalPermissions: false,
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      roleDataForm: {},
      dataRole: {},
      dataUser: {},
      permissionId: '',
      statePermissionModal: '',
      searchText: '',
      debounceSearch: null,
      breadcrumb: [
        {
          string: '',
          active: false,
        },
        {
          string: '',
          active: false,
        },
        {
          string: 'Manage Permissions',
          active: true,
        },
      ],
      rules: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter profile name.',
            trigger: 'blur',
          },
        ],
      },
      dataTree: [],
      filteredData: [],
      renderComponent: true,
      userApplications: [],
      dataApplications: [],
      userRoles: [],
      dataRolesApplication: [],
      activeTabApplication: '',
      applicationIdSelected: null,
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
    searchText() {
      this.debounceSearch();
    },
  },
  computed: {
    ...mapState(['user']),
    stylesContainer() {
      return this.params?.userId ? { display: 'grid', 'grid-template-columns': 'auto 30%' } : { display: 'flex' };
    },
  },
  methods: {
    async handleChangeApplications(newItems) {
      const result = await this.updateUser({ applications: newItems });
      if (result?.data?.success) {
        this.$message({
          type: 'success',
          message: 'Applications updated successfully',
        });
        await this.getUser(this.params?.userId);
      }
    },
    async updateUser(data) {
      this.loading = true;
      try {
        const response = await ApiSSO.put('/users', {
          id: this.dataUser.id,
          ...data,
        });

        return response;
      } catch (error) {
        console.log('🚀 -------------------------------🚀');
        console.log('🚀 ~ updateUser ~ error:', error);
        console.log('🚀 -------------------------------🚀');
        return null;
      } finally {
        this.loading = false;
      }
    },
    async handleChangeRoles(all, direction, values) {
      if (direction === 'left') {
        await this.handleRemoveRoles(values);
      } else if (direction === 'right') {
        await this.handleAddRoles(values);
      }
    },
    async handleAddRoles(newItems) {
      const foundItems = this.dataRolesApplication.filter((role) => newItems.includes(role.id))
        .map((role) => ({
          applicationId: role.applicationId,
          roleId: role.id,
        }));

      const result = await this.updateUser({ newRoles: foundItems });
      if (result?.data?.success) {
        this.$message({
          type: 'success',
          message: 'Roles updated successfully',
        });
        await this.getDataTreeUser(this.params?.userId, this.applicationIdSelected);
      }
    },

    async handleRemoveRoles(deleteItems) {
      const foundItems = this.dataRolesApplication.filter((role) => deleteItems.includes(role.id))
        .map((role) => ({
          applicationId: role.applicationId,
          roleId: role.id,
        }));

      const result = await this.updateUser({ deleteRoles: foundItems });
      if (result?.data?.success) {
        this.$message({
          type: 'success',
          message: 'Roles updated successfully',
        });
        await this.getDataTreeUser(this.params?.userId, this.applicationIdSelected);
      }
    },
    async handleTabClick(tab) {
      this.dataRolesApplication = await this.getRolesByApps(tab.$attrs?.id);
      this.applicationIdSelected = tab.$attrs?.id;
      await this.getDataTreeUser(this.params?.userId, tab.$attrs?.id);
    },
    renderAppNAme(item) {
      return this.dataApplications.find((app) => app.id === item)?.name;
    },
    filterMethod(query, item) {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    async getApplications() {
      this.loading = true;
      try {
        const r = await ApiSSO.get('/applications?ordering=name::ASC');
        if (r.success) {
          return Object.values(r.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
      return [];
    },
    async getRolesByApps(applicationId) {
      this.loading = true;
      try {
        const r = await ApiSSO.get(`/roles/application/${applicationId}`);
        if (r.success) {
          return Object.values(r.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
      return [];
    },
    handleClose() {
      this.$root.$emit('closeModal');
    },
    action(form) {
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.state === 'create') {
            return this.sendCreate();
          }
          if (this.state === 'update') {
            return this.sendUpdate();
          }
        }
        this.loading = false;
        return false;
      });
    },
    reset(form) {
      this.$refs[form].resetFields();
    },
    async sendCreate() {
      try {
        this.roleDataForm.applicationId = this.applicationId;
        const r = await ApiSSO.post('/roles', this.roleDataForm);
        if (r.success) {
          this.loading = false;
          this.dialogVisible = false;
          this.reset('roleDataForm');
          this.$root.$emit('reloadDataRoles');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async sendUpdate() {
      try {
        this.loading = true;
        this.roleDataForm.id = this.id;
        const r = await ApiSSO.put('/roles', this.roleDataForm);
        if (r.success) {
          this.dialogVisible = false;
          this.reset('roleDataForm');
          this.$root.$emit('reloadDataRoles');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getDataTree() {
      try {
        this.loading = true;
        const r = await ApiSSO.get(`/permissions/role/${this.dataRole.id}/application/${this.dataRole.applicationId}`);
        if (r.success) {
          this.dataTree = Object.values(r.data);
          this.filteredData = this.dataTree;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getRole() {
      try {
        const r = await ApiSSO.get(`/roles/${this.roleId}`);
        if (r.success) {
          this.dataRole = { ...r.data };
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    async itemClick(node) {
      this.loading = true;
      if (node.model.selected) {
        if (this.roleId) {
          await this.registerPermission(node.model.id);
        }
        if (this.dataUser.id) {
          await this.registerPermissionUser(node.model.id);
        }
      } else {
        if (this.roleId) {
          await this.removePermission(node.model.id);
        }
        if (this.dataUser.id) {
          await this.removePermissionUser(node.model.id);
        }
      }
      await this.forceRerender();
    },
    async registerPermission(permissionId) {
      try {
        const r = await ApiSSO.post('/permissions/role/add', {
          rolId: this.dataRole.id,
          permissionId,
        });
        if (r.success) {
          this.$message({
            message: 'Permission registered successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async removePermission(permissionId) {
      try {
        const r = await ApiSSO.delete(
          `/permissions/role/${this.dataRole.id}/delete/${permissionId}`,
        );
        if (r.success) {
          this.$message({
            message: 'Permission revoked successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async deletePermission(permissionId) {
      try {
        const r = await ApiSSO.delete(`/permissions/delete/${permissionId}`);
        if (r.success) {
          await this.reloadDataPermissions();

          this.$message({
            message: 'Permission deleted successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    inputKeyUp() {
      const text = this.searchText.toLowerCase();
      this.filteredData = this.searchByText(this.dataTree, text);
    },
    searchByText(data, searchText) {
      const results = [];

      function recursiveSearch(elements) {
        elements.forEach((element) => {
          if (element.text && element.text.toLowerCase().includes(searchText)) {
            results.push(element);
          }
          if (element.children) {
            recursiveSearch(element.children);
          }
        });
      }

      recursiveSearch(data);
      return results;
    },
    xxx() {
      const text = this.searchText.toLowerCase();
      const patt = new RegExp(text);

      const filterNodes = (node) => {
        if (node.model && patt.test(node.model.text.toLowerCase())) {
          return true;
        }
        if (node.children) {
          return node.children.some(filterNodes);
        }
        return false;
      };

      const highlightNodes = (node) => {
        const el = node.$el.querySelector('.tree-anchor');
        if (filterNodes(node)) {
          el.style.color = 'red';
          if (node.children) {
            node.children.forEach(highlightNodes);
          }
        } else {
          el.style.color = '#000';
        }
      };

      this.$refs.tree.handleRecursionNodeChilds(
        this.$refs.tree,
        highlightNodes,
      );
    },
    handleModalPermission(node, item, e, state) {
      e.stopPropagation();
      this.statePermissionModal = state;
      this.permissionId = item?.id ?? null;
      this.showModalPermissions = true;
      // var index = node.parentItem.indexOf(item);
      // node.parentItem.splice(index, 1);
    },
    handleDeletePermission(node, item, e) {
      this.loading = true;
      e.stopPropagation();

      this.$confirm('Are you sure to delete this?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(() => {
          this.deletePermission(item.id);
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            type: 'info',
            message: 'Delete canceled',
          });
        });
    },
    createNewPermission(id, state) {
      this.statePermissionModal = state;
      this.permissionId = id;
      this.showModalPermissions = true;
    },
    async getUser(userId) {
      try {
        const r = await ApiSSO.get(`/users/${userId}`);
        if (r.success) {
          this.dataUser = { ...r.data };

          this.userApplications = this.dataUser.applications
            .split(',')
            .map((item) => item.trim());
          this.userRoles = this.dataUser.roles
            .split(',')
            .map((item) => item.trim());
          this.activeTabApplication = this.params?.applicationId;
          await this.handleTabClick({
            $attrs: { id: this.params?.applicationId },
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getApplication(applicationId) {
      try {
        const r = await ApiSSO.get(`/applications/${applicationId}`);
        if (r.success) {
          this.dataApplication = { ...r.data };
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getDataTreeUser(userId, applicationId) {
      try {
        this.loading = true;
        const r = await ApiSSO.get(`/permissions/user/${userId}/application/${applicationId}`);
        if (r.success) {
          this.dataTree = Object.values(r.data);
          this.filteredData = this.dataTree;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async registerPermissionUser(permissionId) {
      try {
        const r = await ApiSSO.post('/permissions/user/add', {
          userId: this.dataUser.id,
          permissionId,
        });
        if (r.success) {
          this.$message({
            message: 'Permission registered successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async removePermissionUser(permissionId) {
      try {
        const r = await ApiSSO.delete(
          `/permissions/user/${this.dataUser.id}/delete/${permissionId}`,
        );
        if (r.success) {
          this.$message({
            message: 'Permission revoked successfully.',
            type: 'success',
          });
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.showModalPermissions = false;
    },
    async reloadDataPermissions() {
      this.loading = true;
      this.dataTree = [];
      if (this.roleId) {
        await this.getDataTree();
      }
      if (this.userId) {
        await this.getDataTreeUser();
      }

      this.loading = false;
    },
  },
  async mounted() {
    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('reloadDataPermissions', this.reloadDataPermissions);
    if (this.params?.applicationId) {
      await this.getApplication(this.params?.applicationId);
    }

    if (this.params?.roleId) {
      this.roleId = this.params?.roleId;

      await this.getRole();
      this.breadcrumb = [
        {
          type: 'image',
          imgUrl: this.dataApplication.logo,
        },
        {
          string: this.dataApplication.name,
          active: false,
        },
        {
          string: 'Manage Roles',
          active: false,
        },
        {
          string: this.dataRole?.name,
          active: false,
        },
        {
          string: 'Manage Permissions',
          active: true,
        },
      ];

      await this.getDataTree();
    }
    if (this.params?.userId) {
      await this.getUser(this.params?.userId);

      if (this?.dataApplication?.name) {
        this.breadcrumb = [
          {
            type: 'image',
            imgUrl: this?.dataApplication?.logo,
          },
          {
            string: this?.dataApplication?.name,
            active: false,
          },
          {
            string: 'Manage Users',
            active: false,
          },
          {
            string: `${this.dataUser?.firstname} ${this.dataUser?.lastname}`,
            active: false,
          },
          {
            string: 'Manage Permissions',
            active: true,
          },
        ];
      } else {
        this.breadcrumb = [
          {
            string: 'Manage All Users',
            active: false,
          },
          {
            string: `${this.dataUser?.firstname} ${this.dataUser?.lastname}`,
            active: false,
          },
          {
            string: 'Manage Permissions',
            active: true,
          },
        ];
      }
      // await this.getDataTreeUser(this.params?.userId, this.params?.applicationId);
    }

    this.dataApplications = await this.getApplications();

    this.debounceSearch = _.debounce(this.inputKeyUp, 500);
  },
};
</script>

<style lang="scss" scoped>

.permissions-detail {
  // padding-bottom: 20px;
  background: #E5E5E5;

  .title-section {
    font-size: 24px;
  }

  .report-navbar {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    background: #F0F9FD;
    height: 100px;
    align-items: center;
    padding: 0 80px;

    @media (min-width: 720px) {
      padding: 0 90px;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
    }

    .principal-bar {
      justify-self: end;
    }
  }

  .principal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .principal-content {
      width: 1390px;
      // display: grid;
      // grid-template-columns: auto 30%;
      gap: 20px;

      .col-1 {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .col-2 {
        width: 100%;
      }

      .applications-section {
        border: 1px solid transparent;
        border-radius: 10px;
        background: #FFF;
        padding: 0 20px 20px 20px;
      }

      .userApplications {
        border: 1px solid transparent;
        border-radius: 10px;
        background: #FFF;
        padding: 0 20px 20px 20px;
      }

      .permissions-sections {
        border: 1px solid transparent;
        border-radius: 10px;
        background: #FFF;
        padding: 0 20px 20px 20px;
        .search-table {
          margin: 0 !important;
        }

        .container-permissions {
          background: #FFF;
          margin-top: 10px;
          height: 800px;
          overflow-y: auto;
          padding: 10px;

          .btn-create-permission {
            text-align: end;
            padding: 10px 0 10px;
          }
        }
      }
    }
  }
  .el-form--label-top .el-form-item__label {
    padding: 0 !important;
  }

  .el-form-item__label {
    line-height: 30px !important;
    color: #214beb !important;
    font-weight: 700;
    letter-spacing: -0.01em;
  }

  .content-modal-user {
    height: 500px;
    overflow-y: scroll;
    padding: 0 20px 0 0;
    @media (max-width: 424px) {
      height: 400px;
    }
  }
}
</style>
