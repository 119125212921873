<template>
  <div class="remember-device-modal">
    <el-dialog
      :visible.sync="dialogVisible"
      :fullscreen="true"
      class="main-content"
      :close-on-click-modal="false"
      :before-close="handleClose"
      :show-close="false"
    >
      <img :src="logo" alt="Milo Logo" class="milo-logo" />
      <h1>Remember device?</h1>
      <img src="/img/device.png" alt="device" class="device-icon" />
      <el-form
        label-position="top"
        :model="rememberDeviceForm"
        status-icon
        :rules="rules"
        ref="rememberDeviceForm"
        label-width="130px"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <p>Remembering this device allows you to sign in easier and better protect you account</p>

        <el-form-item label="Device name" prop="name">
          <el-input v-model="rememberDeviceForm.name"></el-input>
        </el-form-item>

        <!-- <el-checkbox v-model="rememberDeviceForm.active">Remember this device</el-checkbox> -->

        <el-form-item>
          <el-button
            class="btn-primary"
            :loading="loading"
            type="info"
            :disabled="!rememberDeviceForm.active"
            @click="saveDeviceForm('rememberDeviceForm')"
            @keypress="saveDeviceForm('rememberDeviceForm')"
            ref="submitbtn"
          >
            Remember Device
          </el-button>
        </el-form-item>

        <el-form-item>
          <el-button
            class="btn-secondary"
            :loading="loading"
            type="info"
            @click="handleSkip"
            @keypress="handleSkip"
            ref="skipbtn"
          >
            Skip
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import getBrowserFingerprint from 'get-browser-fingerprint';
import { mapMutations } from 'vuex';
import Utils from '../../utils/Utils';
import logo from '../../assets/sso_icon.png';
import ApiSSO from '../../lib/ApiSSO';

export default {
  name: 'RememberDeviceModal',
  components: {},
  props: ['showModal', 'title', 'data', 'params'],
  data() {
    return {
      Utils,
      logo,
      dialogVisible: this.showModal,
      loading: false,
      codeIsValid: true,
      rememberDeviceForm: {
        userId: this.data.user.id,
        name: '',
        fingerPrint: '',
        active: true,
      },
      rules: {
        active: [
          {
            required: true,
            message: 'This Field is required.',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: 'Please enter device name.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  computed: {
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setRefreshToken']),
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async saveDeviceForm(formName) {
      this.loading = true;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const result = await this.sendSaveRememberDevice();
          this.loading = false;
          return result;
        }
        this.loading = false;
        return false;
      });
    },
    handleSkip() {
      this.dialogVisible = false;
      this.resetForm('rememberDeviceForm');
      if (this?.params) {
        const st = this.params.state ? `&state=${this.params.state}` : '';
        const redirect = `${this.params.redirecturl}?token=${this.data.refreshToken}${st}`;
        window.location.href = redirect;
      } else {
        this.$router.push({ path: '/' });
      }
    },
    sendSaveRememberDevice() {
      return new Promise((resolve, reject) => {
        try {
          ApiSSO.post('/remember-device', this.rememberDeviceForm)
            .then((r) => {
              if (r) {
                if (r.success) {
                  this.loading = false;

                  this.dialogVisible = false;
                  this.resetForm('rememberDeviceForm');

                  this.$emit('saveDevice', r);
                  resolve(true);
                }
              }
            })
            .catch((e) => {
              console.log(e);
              this.loading = false;
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false);
            })
            .finally(() => {
              this.loading = false;
              resolve(true);
            });
        } catch (e) {
          console.error(e);
          this.loading = false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    deviceName(data) {
      return `${data.user.name}-${Utils.getBrowserName()}-${Utils.getDeviceName()}-${data.fingerPrint}`;
    },
  },
  async mounted() {
    this.rememberDeviceForm.fingerPrint = await getBrowserFingerprint();
    this.rememberDeviceForm.name = this.deviceName({
      user: this?.data?.user ?? this?.user,
      fingerPrint: this.rememberDeviceForm.fingerPrint,
    });
  },
};

</script>

<style lang="scss">
.remember-device-modal {
  .main-content {
    z-index: 99999 !important;
  }
  .el-form-item {
    margin: 0 !important;
  }

  .btn-primary {
      width: 100%;
      margin-top: 25px;
  }

  .main-content {
    .el-dialog {
      .el-dialog__body {
        display:flex;
        flex-direction: column;
        align-items: center;
        row-gap: 25px;
        // justify-content: center;
      }
    }
    .demo-ruleForm {
      max-width: 400px;
      width: 100%;

      p{
        word-break: break-word !important;
      }

      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 0;
      padding: 0;
    }
    .milo-logo {
      width: 100%;
      max-width: 120px;
      @media screen and (min-width: 600px) {
        max-width: 156px;
      }
    }
    .device-icon {
      width: 100%;
      max-width: 80px;
      @media screen and (min-width: 600px) {
        max-width: 179px;
      }
    }

    .btn-secondary {
      width: 100%;
    }
  }
}
</style>
