/* eslint-disable */
// eslint-disable-next-line to ignore the next line.

export default {
  capitalizeString(data) {
    let str = data.replaceAll('_', ' ');
    str = str.toLowerCase();
    return str.split(' ').map((i) => {
      if (i.length > 2) {
        return i.charAt(0).toUpperCase() + i.slice(1);
      }

      return i;
    }).join(' ');
  },

  generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },

  getBrowserName() {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf("Chrome") > -1) {
      return "Chrome";
    } else if (userAgent.indexOf("Firefox") > -1) {
      return "Firefox";
    } else if (userAgent.indexOf("Safari") > -1) {
      return "Safari";
    } else if (userAgent.indexOf("Edge") > -1) {
      return "Edge";
    } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
      return "Internet Explorer";
    } else {
      return "Unknown Browser";
    }
  },
  getDeviceName() {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return "Android Device";
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      return "iOS Device";
    } else if (/Windows/i.test(userAgent)) {
      return "Windows Device";
    } else if (/Macintosh/i.test(userAgent)) {
      return "Mac Device";
    } else {
      return "Unknown Device";
    }
  }
};
