<template>
  <div class="audit">
    <div class="report-navbar">
      <breadcrumb-view :items="breadcrumb"></breadcrumb-view>
    </div>
    <simple-table
      @viewRow="viewData"
      @searchRow="handleSearch"
      :showSearch="true"
      :data="audit"
      :tableColumns="columns"
      :loading="loading"
      :firstColumn="firstColumn"
      class="container"
    ></simple-table>

    <simple-paginator
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :currentPage="paginator.currentPage"
      :pageSize="paginator.pageSize"
      :total="paginator.total"
      :pagerCount="5"
      layout="total, sizes, prev, pager, next, jumper"
      class="container"
    ></simple-paginator>

    <view-modal
      v-if="showModalView"
      :showModal="showModalView"
      :data="dataItem">
    </view-modal>
  </div>
</template>

<script>

import ApiSSO from '@/lib/ApiSSO';
import SimpleTable from '@/components/Tables/SimpleTable.vue';
import ViewModal from '@/components/Modals/ViewModal.vue';
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import SimplePaginator from '@/components/Paginator/SimplePaginator.vue';

export default {
  name: 'ClientsView',
  components: {
    BreadcrumbView,
    ViewModal,
    SimpleTable,
    SimplePaginator,
  },
  props: ['brand', 'name'],
  data() {
    return {
      search: null,
      loading: true,
      showModalView: false,
      audit: [],
      dataItem: {},
      breadcrumb: [
        {
          string: 'Home',
          active: false,
          link: '/',
        },
        {
          string: 'Audit',
          active: true,
        },
      ],
      columns: [
        {
          prop: 'userName',
          label: 'User',
          sortable: true,
        },
        {
          prop: 'model',
          label: 'Model',
          sortable: true,
        },
        {
          prop: 'action',
          label: 'Action',
          sortable: true,
        },
        {
          prop: 'createdAt',
          label: 'Created Date',
          sortable: false,
          format: 'fromNow',
        },
        {
          prop: 'actions',
          label: 'Actions',
          sortable: false,
          isActions: true,
          typeButtons: 'links',
          buttons: [
            {
              text: 'View',
              action: 'view',
            },
          ],
        },
      ],
      firstColumn: {
        show: true,
        icon: 'mdi-chart-box-outline',
      },
      paginator: {
        currentPage: 0,
        pageSize: 10,
        total: 0,
        page: 1,
      },
    };
  },
  methods: {
    async getAll(search = null) {
      this.loading = true;
      const paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}`;

      let url = `/audit?${paginate}`;

      if (search) {
        url = `/audit?${paginate}&search=${search}`;
      }

      await ApiSSO.get(url).then((r) => {
        if (r.success) {
          this.audit = Object.values(r.data);

          if (r.paginator) {
            this.paginator.currentPage = r.paginator.page;
            this.paginator.pageSize = r.paginator.page_size;
            this.paginator.total = r.paginator.count;
          }
        }
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    closeModal() {
      this.showModalView = false;
    },
    viewData(row) {
      this.dataItem = row;
      this.showModalView = true;
    },
    currentChange(val) {
      this.paginator.page = val;
      this.getAll();
    },
    sizeChange(val) {
      this.paginator.pageSize = val;
      this.paginator.handlesSizeChange = true;
      this.getAll();
    },
    handleSearch(string) {
      this.search = string;
      this.getAll(string);
    },
    getAllSearch() {
      this.getAll(this.search);
    },
  },
  mounted() {
    this.getAll();
    this.$root.$on('closeModal', this.closeModal);
  },
};
</script>

<style lang="scss" scoped>

.audit {
  .report-navbar{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto ;
    background: #F0F9FD;
    height: 100px;
    align-items: center;
    padding: 0 80px;

    @media (min-width: 720px){
      padding: 0 90px;
      grid-template-rows: auto;
      grid-template-columns: auto auto ;
    }

    .principal-bar{
      justify-self: end;
    }

  }

  .simple-table{
    margin-top: 20px;
  }
}

</style>
