var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"permissions-detail"},[_c('div',{staticClass:"report-navbar"},[_c('breadcrumb-view',{attrs:{"items":_vm.breadcrumb}})],1),_c('div',{staticClass:"principal-container"},[_c('div',{staticClass:"principal-content",style:(_vm.stylesContainer)},[(this.params?.userId)?_c('div',{staticClass:"col-1"},[_c('section',{staticClass:"applications-section"},[_c('h1',{staticClass:"title-section"},[_vm._v("Assign Applications")]),_c('el-transfer',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"custom-transfer",attrs:{"filterable":"","filter-method":_vm.filterMethod,"filter-placeholder":"Search by name","props":{
              key: 'id',
              label: 'name',
            },"titles":['All Applications', 'User Applications'],"button-texts":['', ''],"format":{
              noChecked: '${total}',
              hasChecked: '${checked}/${total}',
            },"data":_vm.dataApplications},on:{"change":_vm.handleChangeApplications},model:{value:(_vm.userApplications),callback:function ($$v) {_vm.userApplications=$$v},expression:"userApplications"}})],1),_c('section',{staticClass:"userApplications"},[_c('h1',{staticClass:"title-section"},[_vm._v("Assign Roles")]),(_vm.userApplications.length > 0)?_c('el-tabs',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"height":"430px"},attrs:{"tab-position":"left","type":"border-card"},on:{"tab-click":_vm.handleTabClick},model:{value:(_vm.activeTabApplication),callback:function ($$v) {_vm.activeTabApplication=$$v},expression:"activeTabApplication"}},_vm._l((_vm.userApplications),function(item){return _c('el-tab-pane',{key:item,attrs:{"label":_vm.renderAppNAme(item),"id":item,"name":item}},[_c('h1',[_vm._v(_vm._s(_vm.renderAppNAme(item)))]),_c('el-transfer',{attrs:{"filterable":"","filter-method":_vm.filterMethod,"filter-placeholder":"Search by name","props":{
                  key: 'id',
                  label: 'name',
                },"titles":['Application Roles', 'User Roles'],"button-texts":['', ''],"format":{
                  noChecked: '${total}',
                  hasChecked: '${checked}/${total}',
                },"data":_vm.dataRolesApplication},on:{"change":_vm.handleChangeRoles},model:{value:(_vm.userRoles),callback:function ($$v) {_vm.userRoles=$$v},expression:"userRoles"}})],1)}),1):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"col-2"},[_c('section',{staticClass:"permissions-sections"},[_c('h1',{staticClass:"title-section"},[_vm._v("Assign Permissions")]),_c('div',{staticClass:"search-table"},[_c('el-input',{attrs:{"clearable":"","size":"mini","prefix-icon":"el-icon-search","placeholder":"Search by name"},on:{"change":_vm.inputKeyUp},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('div',{staticClass:"container-permissions scroll-style-5"},[_c('div',{staticClass:"btn-create-permission"},[(_vm.roleId)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.createNewPermission(
                    'c483cf1f-6751-49d7-9eb2-21996533ca94',
                    'create'
                  )}}},[_vm._v(" Create Permission ")]):_vm._e()],1),_c('el-container',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"permissions"},[(_vm.filteredData.length > 0)?_c('div',[(_vm.renderComponent)?_c('v-jstree',{ref:"tree",attrs:{"data":_vm.filteredData,"show-checkbox":"","multiple":"","allow-batch":false,"whole-row":""},on:{"item-click":_vm.itemClick},scopedSlots:_vm._u([{key:"default",fn:function($){return (_vm.roleId)?[_c('div',{staticStyle:{"display":"inherit","width":"200px"}},[(!$.model.loading)?_c('i',{class:$.vm.themeIconClasses,attrs:{"role":"presentation"}}):_vm._e(),_vm._v(" "+_vm._s($.model.text)+" "),_c('button',{staticStyle:{"border":"0px","background-color":"transparent","cursor":"pointer"},attrs:{"title":"Add new child permission"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleModalPermission($.vm, $.model, $event, 'create')}}},[_c('i',{staticClass:"mdi mdi-plus-circle"})]),_c('button',{staticStyle:{"border":"0px","background-color":"transparent","cursor":"pointer"},attrs:{"title":"Edit permission"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleModalPermission($.vm, $.model, $event, 'update')}}},[_c('i',{staticClass:"mdi mdi-pencil"})]),(!$.model?.children?.length > 0)?_c('button',{staticStyle:{"border":"0px","background-color":"transparent","cursor":"pointer"},attrs:{"title":"Delete permission"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleDeletePermission($.vm, $.model, $event)}}},[_c('i',{staticClass:"mdi mdi-delete"})]):_vm._e()])]:undefined}}],null,true)}):_vm._e()],1):_c('el-empty',{attrs:{"image-size":200,"description":"No permissions created for this application"}},[(_vm.roleId)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.createNewPermission(
                      'c483cf1f-6751-49d7-9eb2-21996533ca94',
                      'create'
                    )}}},[_vm._v(" Create Permission ")]):_vm._e()],1)],1)],1)])])])]),(_vm.showModalPermissions)?_c('permissions-modal',{attrs:{"showModal":_vm.showModalPermissions,"permissionId":_vm.permissionId,"applicationId":_vm.dataRole.applicationId ?? _vm.user.applicationId,"title":"Permission","state":_vm.statePermissionModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }